import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'

function AuthZeroProvider({children}) {
  return (
    <Auth0Provider
    domain="dev-tkwl7tybiujlsf2b.us.auth0.com"
    clientId="9PcVtDuUbJqiXcjw3kpyiHGqk0v3kzvz"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience:"https://api.jobflowconnect.com",
      scope: "openid profile email"
    }}
  >
    {children}
  </Auth0Provider>
  )
}

export default AuthZeroProvider