import { Utils } from 'core/utils'
import React from 'react'
import { Link } from 'react-router-dom'

function CompanyItem({ company }) {
    // Ensure the website URL has the proper protocol


    return (
        <div class="card-employers hover-up wow animate__animated animate__fadeIn">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6 d-flex">
                    <div class="employers-logo online mr-15">
                        <a href="employers-single.html">
                            <figure><img alt="jobhub" src="assets/imgs/page/employers/employer-1.png" /></figure>
                        </a>
                    </div>
                    <div class="employers-name">
                        <h5><a href="employers-single.html"><strong>{company.title}</strong></a></h5>
                        <span class="text-sm text-muted">UI/UX Designer</span>
                        <div class="d-flex mt-15">
                            <div class="rate small float-start">
                                <input type="radio" id="star5" name="rate" value="5" />
                                <label for="star5" title="text" class="">5 stars</label>
                                <input type="radio" id="star4" name="rate" value="4" />
                                <label for="star4" title="text" class="checked">4 stars</label>
                                <input type="radio" id="star3" name="rate" value="3" />
                                <label for="star3" title="text" class="checked">3 stars</label>
                                <input type="radio" id="star2" name="rate" value="2" />
                                <label for="star2" title="text" class="checked">2 stars</label>
                                <input type="radio" id="star1" name="rate" value="1" />
                                <label for="star1" title="text" class="checked">1 star</label>
                            </div>
                            <span class="text-muted text-small">(5.0)</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="employers-info d-flex align-items-center">
                        <span class="d-flex align-items-center kfont"><i class="fi-rr-marker mr-5 ml-0 "></i> {company.city}, US</span>
                        <span class="d-flex align-items-center ml-25"><i class="fi-rr-briefcase mr-5"></i>Software</span>
                    </div>
                    <div class="job-tags mt-25">
                        <a href="job-grid.html" class="hover-up">Figma</a>
                        <a href="job-grid.html" class="hover-up">Adobe XD</a>
                        <a href="job-grid.html" class="hover-up">PSD</a>
                    </div>
                </div>
                <div class="col-lg-3 text-lg-end d-lg-block d-none">
                    <div class="card-grid-2-link">
                        <a href="#"><i class="fi-rr-shield-check"></i></a>
                        <a href="#"><i class="fi-rr-bookmark"></i></a>
                    </div>
                    <div class="mt-25">
                        <a href="job-grid.html" class="btn btn-border btn-brand-hover">12 Open Jobs</a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CompanyItem;
