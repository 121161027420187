import { UserHttpService } from 'cms/features/feature_user/service';
import { useState } from 'react';

export const useCertifications = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const addCertification = async (data) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.addCertification(data);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const updateCertification = async (data) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.updateCertification(data);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const deleteCertification = async (id) => {
        setIsLoading(true);
        setError(null);
        try {
            await UserHttpService.deleteCertification(id);
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        addCertification,
        updateCertification,
        deleteCertification,
        isLoading,
        error
    };
};

export default useCertifications; 