import React, { useState } from 'react';
import { Paper, Typography, Box, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from 'cms/core/shared/dialogs/CustomModal';

const QualificationSection = ({
    title,
    items,
    onAdd,
    onEdit,
    onDelete,
    renderItem,
    renderForm,
    addButtonText = 'Add New',
    error
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleAdd = () => {
        setSelectedItem(null);
        setIsModalOpen(true);
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        setIsDeleteModalOpen(true);
    };

    const handleSubmit = async (data) => {
        if (selectedItem) {
            await onEdit({ ...data, id: selectedItem.id });
        } else {
            await onAdd(data);
        }
        setIsModalOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (selectedItem) {
            await onDelete(selectedItem.id);
        }
        setIsDeleteModalOpen(false);
    };

    // Don't render anything if items is undefined or null
    if (items === undefined || items === null) {
        return null;
    }

    return (
        <Paper elevation={1} className="p-4 mb-4">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h6">{title}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAdd}
                >
                    {addButtonText}
                </Button>
            </Box>

            {error && (
                <Typography color="error" className="mb-3">
                    {error}
                </Typography>
            )}

            {items.length > 0 ? (
                <Box>
                    {items.map((item) => (
                        <Box 
                            key={item.id} 
                            className="p-3 mb-3 border rounded"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Box flex={1}>
                                {renderItem(item)}
                            </Box>
                            <Box>
                                <IconButton 
                                    size="small" 
                                    onClick={() => handleEdit(item)}
                                    className="mr-1"
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => handleDelete(item)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Typography color="textSecondary" className="text-center py-4">
                    No items added yet
                </Typography>
            )}

            <CustomModal
                open={isModalOpen}
                setOpen={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={selectedItem ? 'Edit Item' : 'Add New Item'}
            >
                {renderForm({ 
                    onSubmit: handleSubmit, 
                    initialData: selectedItem,
                    onCancel: () => setIsModalOpen(false)
                })}
            </CustomModal>

            <CustomModal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title="Confirm Delete"
            >
                <Box>
                    <Typography>Are you sure you want to delete this item?</Typography>
                    <Box mt={3} display="flex" justifyContent="flex-end">
                        <Button 
                            onClick={() => setIsDeleteModalOpen(false)} 
                            className="mr-2"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained" 
                            color="error" 
                            onClick={handleConfirmDelete}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </CustomModal>
        </Paper>
    );
};

export default QualificationSection; 