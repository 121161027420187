import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Button, MenuItem, CircularProgress, Typography } from '@mui/material';
import useSkillForm from '../../hooks/useSkillForm';
import useSkills from '../../hooks/useSkills';
import useProfile from '../../hooks/UseProfile';

const proficiencyLevels = [
    'Beginner',
    'Intermediate',
    'Advanced',
    'Expert'
];

const SkillForm = ({ onClose, initialData, id }) => {

    console.log(initialData)
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            skillName: '',
            proficiencyLevel: 'Intermediate',
            years_of_experience: '',
        }
    });

    const { isLoading: isSaving, error: saveError, data, saveSkill } = useSkillForm(id);
    const { loadSkills } = useSkills();
    const { refreshProfile } = useProfile();

    useEffect(() => {
        if (data) {
            // Extract skill name and proficiency level from title
            const titleParts = data.title ? data.title.split(' - ') : ['', 'Intermediate'];
            const formData = {
                ...data,
                skillName: titleParts[0],
                proficiencyLevel: titleParts[1] || 'Intermediate',
            };
            reset(formData);
        }
    }, [data, reset]);

    const handleFormSubmit = async (formData) => {
        try {
            // Combine skill name and proficiency level into title
            const processedData = {
                title: `${formData.skillName} - ${formData.proficiencyLevel}`,
                years_of_experience: parseInt(formData.years_of_experience, 10)
            };
            await saveSkill(processedData);
            await Promise.all([
                loadSkills(), // Refresh the skills list
                refreshProfile() // Refresh the profile data
            ]);
            onClose();
        } catch (err) {
            // Error is handled by the hook and displayed in the form
        }
    };

    if (isSaving) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box className="space-y-4">
                {saveError && (
                    <Typography color="error" className="mb-4">
                        {saveError}
                    </Typography>
                )}

                <TextField
                    fullWidth
                    label="Skill Name"
                    {...register('skillName', { required: 'Skill name is required' })}
                    error={!!errors.skillName}
                    className='mb-2'
                    helperText={errors.skillName?.message}
                />

                <TextField
                    fullWidth
                    select
                    label="Proficiency Level"
                    {...register('proficiencyLevel', { required: 'Proficiency level is required' })}
                    error={!!errors.proficiencyLevel}
                    helperText={errors.proficiencyLevel?.message}
                    className='mb-2'
                >
                    {proficiencyLevels.map((level) => (
                        <MenuItem key={level} value={level}>
                            {level}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Years of Experience"
                    {...register('years_of_experience', { 
                        required: 'Years of experience is required',
                        min: {
                            value: 0,
                            message: 'Years must be 0 or greater'
                        },
                        max: {
                            value: 50,
                            message: 'Years cannot exceed 50'
                        }
                    })}
                    className='mb-2'
                    error={!!errors.years_of_experience}
                    helperText={errors.years_of_experience?.message}
                />

                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update' : 'Add'} Skill
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default SkillForm; 