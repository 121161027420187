import { UseJobList } from 'cms/features/feature_job/hooks';
import React from 'react'
import JobItem from './JobItem';
import JobsSidebar from './JobsSidebar';

function JobsList() {

    const { data, isLoading, isError } = UseJobList({ size: 30 });

  return (
    <section class="section-box mt-80">
            <div class="container">
                <div class="row flex-row-reverse">
                    <div class="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
                        <div class="content-page">
                            <div class="box-filters-job mt-15 mb-10">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <span class="text-small">Showing <strong>41-60 </strong>of <strong>944 </strong>jobs</span>
                                    </div>
                                    <div class="col-lg-5 text-lg-end mt-sm-15">
                                        <div class="display-flex2">
                                            <span class="text-sortby">Sort by:</span>
                                            <div class="dropdown dropdown-sort">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownSort" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span> <i class="fi-rr-angle-small-down"></i></button>
                                                <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                                    <li><a class="dropdown-item active" href="#">Newest Post</a></li>
                                                    <li><a class="dropdown-item" href="#">Oldest Post</a></li>
                                                    <li><a class="dropdown-item" href="#">Rating Post</a></li>
                                                </ul>
                                            </div>
                                            <div class="box-view-type">
                                                <a href="job-grid.html" class="view-type"><img src="assets/imgs/theme/icons/icon-list.svg" alt="jobhub" /></a>
                                                <a href="job-list.html" class="view-type"><img src="assets/imgs/theme/icons/icon-grid.svg" alt="jobhub" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="job-list-list mb-15">
                                <div class="list-recent-jobs">
                                    {data && data.results?.map((job) => ( 
                                            <JobItem job={job} />
                                    ))}
                                </div>
                            </div>
                            <div class="paginations">
                                <ul class="pager">
                                    <li><a href="#" class="pager-prev"></a></li>
                                    <li><a href="#" class="pager-number">1</a></li>
                                    <li><a href="#" class="pager-number">2</a></li>
                                    <li><a href="#" class="pager-number">3</a></li>
                                    <li><a href="#" class="pager-number">4</a></li>
                                    <li><a href="#" class="pager-number">5</a></li>
                                    <li><a href="#" class="pager-number active">6</a></li>
                                    <li><a href="#" class="pager-number">7</a></li>
                                    <li><a href="#" class="pager-next"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <JobsSidebar />
                </div>
            </div>
        </section>
  )
}

export default JobsList