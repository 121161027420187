// src/components/CVTitleForm.jsx
import { JobHttpService } from 'cms/features/feature_job/service';
import React from 'react';
import { useForm } from 'react-hook-form';
// import SelectCountry from 'core/layout/widgets/selects/SelectCountry'; // adjust the path as needed

const CVTitleForm = ({ cv, onUpdate }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: cv ? cv.firstName : '',
      lastName: cv ? cv.lastName : '',
      role: cv ? cv.title : '',
      description: cv ? cv.description : '',
      interests: cv ? cv.interests || '' : '',
      email: cv ? cv.email : '',
      phone: cv ? cv.phoneNumber : '',
      website: cv ? cv.website : '',
      city: cv ? cv.city : '',
      country: cv ? (cv.country && cv.country.id ? cv.country.id.toString() : '') : '',
      address: cv ? cv.address : '',
    },
  });

  // Handler for saving changes
  const onSave = async (formData) => {
    const body = {
      id: cv ? cv.id : null,
      name: formData.firstName,
      last_name: formData.lastName,
      title: formData.role,
      description: formData.description,
      email: formData.email,
      phone: formData.phone,
      website: formData.website,
      interests: formData.interests,
      city: formData.city,
      country: formData.country,
      address: formData.address,
    };

    try {
      const response = await JobHttpService.saveCV(body);
      if (response.data) {
        // Optionally, trigger a refresh of the CV list here
        if (onUpdate) onUpdate(response.data);
      }
    } catch (error) {
      console.error('Error saving CV:', error);
    }
  };

  // Handler for deleting the CV (only available if cv exists)
  const onDelete = async () => {
    if (!cv) return;
    try {
      const response = await JobHttpService.deleteCV(cv.id);
      if (onUpdate) onUpdate(response.data);
    } catch (error) {
      console.error('Error deleting CV:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)} style={{ padding: '15px' }}>
      {/* Name and Last Name */}
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <div style={{ flex: 1 }}>
          <label>Name</label>
          <input
            type="text"
            {...register('firstName', { required: 'Name is required' })}
          />
          {errors.firstName && <span>{errors.firstName.message}</span>}
        </div>
        <div style={{ flex: 1 }}>
          <label>Last Name</label>
          <input
            type="text"
            {...register('lastName', { required: 'Last Name is required' })}
          />
          {errors.lastName && <span>{errors.lastName.message}</span>}
        </div>
      </div>

      {/* Role */}
      <div style={{ marginBottom: '10px' }}>
        <label>Role</label>
        <input
          type="text"
          {...register('role', { required: 'Role is required' })}
        />
        {errors.role && <span>{errors.role.message}</span>}
      </div>

      {/* Description / About */}
      <div style={{ marginBottom: '10px' }}>
        <label>About</label>
        <textarea {...register('description')} rows={3} />
      </div>

      {/* Interests */}
      <div style={{ marginBottom: '10px' }}>
        <label>Interests</label>
        <textarea {...register('interests')} rows={3} />
      </div>

      {/* Email */}
      <div style={{ marginBottom: '10px' }}>
        <label>Email</label>
        <input
          type="email"
          {...register('email', { required: 'Email is required' })}
        />
        {errors.email && <span>{errors.email.message}</span>}
      </div>

      {/* Phone Number */}
      <div style={{ marginBottom: '10px' }}>
        <label>Phone Number</label>
        <input type="text" {...register('phone')} />
      </div>

      {/* Website */}
      <div style={{ marginBottom: '10px' }}>
        <label>Website</label>
        <input type="text" {...register('website')} />
      </div>

      {/* Location Information */}
      <div style={{ marginTop: '30px', fontWeight: 'bold', fontSize: '16px' }}>
        Location Information
      </div>
      <div style={{ marginBottom: '10px' }}>
        {/* <SelectCountry
          key={watch('country')}
          value={watch('country')}
          onSelect={(value) => setValue('country', value)}
        /> */}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>City</label>
        <input type="text" {...register('city')} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>Address</label>
        <input type="text" {...register('address')} />
      </div>

      {/* Action Buttons */}
      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <button className='btn btn-primary' type="submit">Save</button>
        {cv && (
          <button type="button" onClick={onDelete} style={{ backgroundColor: 'red', color: 'white' }}>
            Delete
          </button>
        )}
      </div>
      
      {/* Extra spacing */}
      <div style={{ height: '200px' }}></div>
    </form>
  );
};

export default CVTitleForm;
