import CustomModal from 'cms/core/shared/dialogs/CustomModal';
import LoginBox from 'features/feature_user/components/LoginBox';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import useAuth0ServerLogin from 'features/feature_user/hooks/useAuth0ServerLogin';

function HeaderSignInButton() {

    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

    const { user, isAuthenticated, loginWithRedirect,logout,getAccessTokenSilently   } = useAuth0();

    const { data: serverTokenData, isLoading, isError, refetchAuth } = useAuth0ServerLogin();

    useEffect(() => {
        if (isAuthenticated) {
          // Optionally, refetch the server login when the authentication status changes.
          refetchAuth();
        }
      }, [isAuthenticated]);

    // useEffect(() => {
    //     // Only fetch token if the user is authenticated
    //     if (isAuthenticated) {
    //       const fetchToken = async () => {
    //         try {
    //           const token = await getAccessTokenSilently();
    //         //   setAccessToken(token);
    //         } catch (error) {
    //           console.error("Error fetching access token:", error);
    //         }
    //       };
    
    //       fetchToken();
    //     }  
    //   }, [isAuthenticated, getAccessTokenSilently]);


    return (
        <>
            {isAuthenticated ? (
            <Link  onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="btn btn-default btn-shadow ml-40 hover-up">{user.name}</Link>

            ) : (
                <Link onClick={() => loginWithRedirect()} className="btn btn-default btn-shadow ml-40 hover-up">Sign in</Link>

            )}

            <CustomModal big key={loginModalIsOpen} setOpen={(value) => setLoginModalIsOpen(value)}
                open={loginModalIsOpen} >
                    <LoginBox />
            </CustomModal>
        </>
    )
}

export default HeaderSignInButton