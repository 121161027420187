import { useState, useEffect } from 'react';
import { UserHttpService } from 'core/services/user';

const useLanguageForm = (id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const loadLanguage = async () => {
            if (!id) return;
            
            setIsLoading(true);
            setError(null);
            
            try {
                const response = await UserHttpService.getLanguage(id);
                setData(response.data);
            } catch (err) {
                setError(err.message || 'Failed to load language');
            } finally {
                setIsLoading(false);
            }
        };

        loadLanguage();
    }, [id]);

    const saveLanguage = async (formData) => {
        setIsLoading(true);
        setError(null);

        try {
            if (id) {
                await UserHttpService.updateLanguage(id, formData);
            } else {
                await UserHttpService.createLanguage(formData);
            }
        } catch (err) {
            setError(err.message || 'Failed to save language');
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        saveLanguage
    };
};

export default useLanguageForm; 