import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Button, MenuItem, CircularProgress, Typography } from '@mui/material';
import useLanguageForm from '../../hooks/useLanguageForm';
import useProfile from '../../hooks/UseProfile';

const proficiencyLevels = [
    'Elementary',
    'Limited Working',
    'Professional Working',
    'Full Professional',
    'Native/Bilingual'
];

const LanguageForm = ({ onCancel, id }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            languageName: '',
            proficiencyLevel: 'Professional Working',
            notes: ''
        }
    });

    const { isLoading, error, data, saveLanguage } = useLanguageForm(id);
    const { refreshProfile } = useProfile();

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data, reset]);

    const handleFormSubmit = async (formData) => {
        try {
            await saveLanguage(formData);
            await refreshProfile();
            onCancel();
        } catch (err) {
            // Error is handled by the hook and displayed in the form
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box className="space-y-4">
                {error && (
                    <Typography color="error" className="mb-4">
                        {error}
                    </Typography>
                )}

                <TextField
                    fullWidth
                    label="Language"
                    {...register('languageName', { required: 'Language name is required' })}
                    error={!!errors.languageName}
                    helperText={errors.languageName?.message}
                />

                <TextField
                    fullWidth
                    select
                    label="Proficiency Level"
                    {...register('proficiencyLevel', { required: 'Proficiency level is required' })}
                    error={!!errors.proficiencyLevel}
                    helperText={errors.proficiencyLevel?.message}
                >
                    {proficiencyLevels.map((level) => (
                        <MenuItem key={level} value={level}>
                            {level}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Additional Notes"
                    {...register('notes')}
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                />

                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update' : 'Add'} Language
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default LanguageForm; 