import React from 'react';
import { Box, Avatar, Typography, Paper } from '@mui/material';
import { Utils } from 'core/utils';

const ProfileDetails = ({ profile }) => {
    const getProfileImage = (image) => {
        if (!image) return '/default-avatar.png';
        if (image.includes('/api/uploaded_media/https')) {
            return image.replace('/api/uploaded_media/', '');
        }
        return image;
    };

    return (
        <Paper elevation={1} className="p-4 mb-4">
            <Box display="flex" alignItems="center">
                <Avatar
                    src={getProfileImage(profile?.image)}
                    alt={profile?.first_name}
                    sx={{ width: 100, height: 100, marginRight: 3 }}
                />
                <Box>
                    <Typography variant="h5" className="mb-2">
                        {profile?.first_name} {profile?.last_name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className="mb-1">
                        <i className="fi-rr-marker mx-1"></i>
                        {profile?.city || 'Location not specified'}
                        {profile?.country?.name && `, ${profile.country.name}`}
                    </Typography>
                    {profile?.phone_number && (
                        <Typography variant="body1" color="textSecondary" className="mb-1">
                            <i className="fi-rr-phone-call mx-1"></i>
                            {profile.phone_number}
                        </Typography>
                    )}
                    <Typography variant="body1" color="textSecondary">
                        <i className="fi-rr-envelope mx-1"></i>
                        {profile?.email || 'Email not specified'}
                    </Typography>
                </Box>
            </Box>
            {profile?.about && (
                <Box mt={3}>
                    <Typography variant="h6" className="mb-2">About</Typography>
                    <Typography variant="body1">{profile.about}</Typography>
                </Box>
            )}
        </Paper>
    );
};

export default ProfileDetails; 