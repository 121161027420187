import { HttpService } from "core/services/http";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const localStorageToken = HttpService.getToken()


return children; // localStorageToken ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;