import { useState, useEffect } from 'react';
import { UserHttpService } from 'core/services/user';

const useSkills = () => {
    const [skills, setSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const loadSkills = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.listSkills();
            setSkills(response.results || []);
        } catch (err) {
            setError(err.message || 'Failed to load skills');
        } finally {
            setIsLoading(false);
        }
    };

    const deleteSkill = async (id) => {
        try {
            await UserHttpService.deleteSkill(id);
            // Refresh the skills list after deletion
            await loadSkills();
        } catch (err) {
            setError(err.message || 'Failed to delete skill');
            throw err;
        }
    };

    useEffect(() => {
        loadSkills();
    }, []);

    return {
        skills,
        isLoading,
        error,
        loadSkills,
        deleteSkill
    };
};

export default useSkills; 