import React, { useState } from 'react';
import { Typography, Box, Chip } from '@mui/material';
import QualificationSection from './QualificationSection';
import LanguageForm from '../forms/LanguageForm';
import useLanguages from '../../hooks/useLanguages';
import useProfile from '../../hooks/UseProfile';

const proficiencyColors = {
    'Native': 'success',
    'Fluent': 'primary',
    'Advanced': 'info',
    'Intermediate': 'warning',
    'Basic': 'default'
};

const LanguageSection = ({ languages = [] }) => {
    const { addLanguage, updateLanguage, deleteLanguage } = useLanguages();
    const { refreshProfile } = useProfile();
    const [error, setError] = useState(null);

    const handleAdd = async (data) => {
        try {
            await addLanguage(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to add language');
        }
    };

    const handleUpdate = async (data) => {
        try {
            await updateLanguage(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to update language');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteLanguage(id);
            await refreshProfile();
        } catch (err) {
            setError('Failed to delete language');
        }
    };

    const renderLanguageItem = (lang) => (
        <Box display="flex" alignItems="center" gap={2}>
            <Box flex={1}>
                <Typography variant="subtitle1" className="font-semibold">
                    {lang.language}
                </Typography>
                {lang.description && (
                    <Typography variant="body2" className="mt-1">
                        {lang.description}
                    </Typography>
                )}
            </Box>
            <Chip 
                label={lang.proficiencyLevel}
                color={proficiencyColors[lang.proficiencyLevel] || 'default'}
                size="small"
            />
        </Box>
    );

    const renderLanguageForm = ({ onSubmit, initialData, onCancel }) => (
        <LanguageForm 
            onSubmit={onSubmit}
            initialData={initialData}
            onCancel={onCancel}
        />
    );

    return (
        <QualificationSection
            title="Languages"
            items={languages}
            onAdd={handleAdd}
            onEdit={handleUpdate}
            onDelete={handleDelete}
            renderItem={renderLanguageItem}
            renderForm={renderLanguageForm}
            addButtonText="Add Language"
            error={error}
        />
    );
};

export default LanguageSection; 