import { HttpService } from "core/services/http";

export class JobHttpService {

    
    static async loadCV(id) {
        var service = new HttpService();
        var response = await service.get(`/job/cv/${id}`, null);
        return response;
    }

  
    static async saveCV(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/job/cv/${body.id}`, body);
        }
        else
            response = await service.post('/job/cv/', body);

        return await response
    }

    static async loadCVList({page=1,size=30}) {
        var service = new HttpService();
        var response = await service.get(`/job/cv/?page=${page}&size=${size}`, null);
        return response;
    }

    static async saveBenefit(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/job/benefit/${body.id}`, body);
        }
        else
            response = await service.post('/job/benefit/', body);

        return await response
    }

    static async loadBenefit(id) {
        var service = new HttpService();
        var response = await service.get(`/job/benefit/${id}`, null);
        return response;
    }

    static async loadBenefits(page=1) {
        var service = new HttpService();
        var response = await service.get('/job/benefit/', null);
        return response;
    }

    static async loadJob(id) {
        var service = new HttpService();
        var response = await service.get(`/job/${id}`, null);
        return response;
    }

    static async loadJobs({ page = 1, size = 30 } = {}) {
        const service = new HttpService();
        const response = await service.get('/job/', 
          {
            page,
            size
          }
        );
        return response;
    }

    static async loadCompanies({ page = 1, size = 30 } = {}) {
        const service = new HttpService();
        const response = await service.get('/job/company/', 
          {
            page,
            size
          }
        );
        return response;
    }

    static async loadCandidates({ page = 1, size = 30 } = {}) {
        const service = new HttpService();
        const response = await service.get('/job/jobseekers/', 
          {
            page,
            size
          }
        );
        return response;
    }

    static async loadCandidate(id) {
        const service = new HttpService();
        const response = await service.get(`/job/jobseekers/${id}`, {
            include: 'experiences,education'
        });
        return response;
    }
   
}

