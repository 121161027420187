import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react'
import AdminLayout from 'cms/core/shared/AdminLayout';

function Dashboard() {

    return (
        <AdminLayout>
                <div className="container">
                    <div className="row">
                        hi
                    </div>
                </div>
        </AdminLayout>
    )
}


export default Dashboard