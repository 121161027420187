import React from 'react'

function JobsSidebar() {
    return (
        <div class="col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="sidebar-with-bg">
                <h5 class="font-semibold mb-10">Set job reminder</h5>
                <p class="text-body-999">Enter you email address and get job notification.</p>
                <div class="box-email-reminder">
                    <form>
                        <div class="form-group mt-15">
                            <input type="text" class="form-control input-bg-white form-icons" placeholder="Enter email address" />
                            <i class="fi-rr-envelope"></i>
                        </div>
                        <div class="form-group mt-25 mb-5">
                            <button class="btn btn-default btn-md">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="sidebar-shadow none-shadow mb-30">
                <div class="sidebar-filters">
                    <div class="filter-block mb-30">
                        <h5 class="medium-heading mb-15">Location</h5>
                        <div class="form-group">
                            <input type="text" class="form-control form-icons" placeholder="Location" />
                            <i class="fi-rr-marker"></i>
                        </div>
                    </div>
                    <div class="filter-block mb-30">
                        <h5 class="medium-heading mb-15">Categoy</h5>
                        <div class="form-group select-style select-style-icon">
                            <select class="form-control form-icons select-active">
                                <option>Ui/UX design</option>
                                <option>Ui/UX design 1</option>
                                <option>Ui/UX design 2</option>
                                <option>Ui/UX design 3</option>
                            </select>
                            <i class="fi-rr-briefcase"></i>
                        </div>
                    </div>
                    <div class="filter-block mb-30">
                        <h5 class="medium-heading mb-15">Job type</h5>
                        <div class="form-group">
                            <ul class="list-checkbox">
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Full Time
                                            Jobs</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">235</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" checked="checked" /> <span class="text-small">Part Time
                                            Jobs</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">28</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" checked="checked" /> <span class="text-small">Remote
                                            Jobs</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">67</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Freelance</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">92</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Temporary</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">14</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-block mb-30">
                        <h5 class="medium-heading mb-10">Experience Level</h5>
                        <div class="form-group">
                            <ul class="list-checkbox">
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Expert</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">76</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Senior</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">89</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" checked="checked" /> <span class="text-small">Junior</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">54</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" checked="checked" /> <span class="text-small">Regular</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">23</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Internship</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">22</span>
                                </li>
                                <li>
                                    <label class="cb-container">
                                        <input type="checkbox" /> <span class="text-small">Associate</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="number-item">14</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-block mb-40">
                        <h5 class="medium-heading mb-25">Salary Range</h5>
                        <div class="">
                            <div class="row mb-20">
                                <div class="col-sm-12">
                                    <div id="slider-range"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="lb-slider">From</label>
                                    <div class="form-group minus-input">
                                        <input type="text" name="min-value-money" class="input-disabled form-control min-value-money" disabled="disabled" value="" />
                                        <input type="hidden" name="min-value" class="form-control min-value" value="" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="lb-slider">To</label>
                                    <div class="form-group">
                                        <input type="text" name="max-value-money" class="input-disabled form-control max-value-money" disabled="disabled" value="" />
                                        <input type="hidden" name="max-value" class="form-control max-value" value="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-filter">
                        <button class="btn btn-default">Apply filter</button>
                        <button class="btn">Reset filter</button>
                    </div>
                </div>
            </div>
            <div class="sidebar-with-bg background-primary bg-sidebar pb-80">
                <h5 class="medium-heading text-white mb-20 mt-20">Recruiting?</h5>
                <p class="text-body-999 text-white mb-30">Advertise your jobs to millions of monthly users
                    and
                    search 16.8 million CVs in our database.</p>
                <a href="job-grid-2.html" class="btn btn-border icon-chevron-right btn-white-sm">Post a Job</a>
            </div>
        </div>
    )
}

export default JobsSidebar