import { useQuery, useQueryClient } from "react-query";
import { JobHttpService } from "../service";

/**
 * A custom React hook for fetching and paginating explore posts.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 * }}
 */
function UseJobList({size = 30}) {
    const queryClient = useQueryClient();

    const {
        data,
        isLoading,
        isError,
    } = useQuery(
        ["job_list",size],
        () => {
            return JobHttpService.loadJobs({size: size})
        },
        {
            onSuccess: (data) => {
                // console.log(data);
            }
        }
    );

    const removeItemFromList = (jobId) => {
        queryClient.setQueryData(["job_list"], oldData => ({
            ...oldData,
            results: oldData.results.filter(job => job.id !== jobId)
        }));
    };

    return { data, isLoading, isError, removeItemFromList };
}

export default UseJobList;