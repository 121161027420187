// src/components/CVItem.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';


const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const CVItem = ({ cv }) => {
  const navigate = useNavigate();

  const goToCVDetails = (cv) => {
    // Navigate to the CV details page, e.g., /cv/details/{cv.id}
    navigate(`/cv/${cv.id}`);
  };

  return (
    <div
      onClick={() => goToCVDetails(cv)}
      style={{
        cursor: 'pointer',
        margin: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        borderRadius: '4px',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {/* Colored side border */}
      <div style={{ width: '5px', backgroundColor: 'blue' }}></div>
      <div style={{ flex: 1, padding: '16px' }}>
        <div style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '18px' }}>
          {cv.title}
        </div>
        <div style={{ fontSize: '16px' }}>
          Updated: {formatDate(cv.updated_at)}
        </div>
      </div>
    </div>
  );
};

export default CVItem;
