import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import ProfileDetails from '../components/profile/ProfileDetails';
import SkillsSection from '../components/profile/SkillsSection';
import EducationSection from '../components/profile/EducationSection';
import CertificationSection from '../components/profile/CertificationSection';
import LanguageSection from '../components/profile/LanguageSection';
import useProfile from '../hooks/UseProfile';
import ProfileEditScreen from '../components/ProfileEditScreen';
import MainLayout from 'core/layout/MainLayout';

const ProfilePage = () => {
    const { data: profile, isLoading, error, updateProfile } = useProfile();
    const [isEditing, setIsEditing] = useState(false);

    const handleProfileUpdate = async (data) => {
        try {
            await updateProfile(data);
            setIsEditing(false);
        } catch (err) {
            // Error handling is done within the hook
        }
    };

  

    return (
        <MainLayout>
            <div className="container">
                <Box className="space-y-6 py-6">
                    {isEditing ? (
                        <ProfileEditScreen
                            profile={profile}
                            onSubmit={handleProfileUpdate}
                            onCancel={() => setIsEditing(false)}
                        />
                    ) : (
                        <ProfileDetails
                            profile={profile}
                            onEdit={() => setIsEditing(true)}
                        />
                    )}
                    <SkillsSection  />
                    <EducationSection />
                    <CertificationSection certifications={profile?.certifications} />
                    <LanguageSection languages={profile?.languages} />
                </Box>
            </div>
        </MainLayout>
    );
};

export default ProfilePage;