import { UserHttpService } from 'cms/features/feature_user/service';
import { useState } from 'react';

export const useLanguages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const addLanguage = async (data) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.addLanguage(data);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const updateLanguage = async (data) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.updateLanguage(data);
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const deleteLanguage = async (id) => {
        setIsLoading(true);
        setError(null);
        try {
            await UserHttpService.deleteLanguage(id);
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        addLanguage,
        updateLanguage,
        deleteLanguage,
        isLoading,
        error
    };
};

export default useLanguages; 