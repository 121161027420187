import MainLayout from 'core/layout/MainLayout'
import React from 'react'
import JobsFilterWidget from '../components/JobsFilterWidget'
import CompanyList from '../components/CompanyList'

function CompaniesPage() {
  return (
    <MainLayout>
        <JobsFilterWidget />
        <CompanyList />
        
        
    </MainLayout>
  )
}

export default CompaniesPage