import React from 'react'
import { Link } from 'react-router-dom';

function CandidateItem({candidate}) {
    const str = 'https://jobflowconnect.com/api/uploaded_media/https';
    const image = candidate.profile.image 
        ? (candidate.profile.image.includes(str) ? candidate.profile.image.replace(str,'https') : candidate.profile.image)
        : '/assets/imgs/page/candidates/candidate-default.png';

    return (
        <div className="card-grid-2 hover-up">
            <div className="card-grid-2-link">
                <a href="#"><i className="fi-rr-shield-check"></i></a>
                <a href="#"><i className="fi-rr-bookmark"></i></a>
            </div>
            <div className="text-center card-grid-2-image-rd online">
                <Link to={`/candidate/${candidate.id}`}>
                    <figure><img alt={candidate.profile.first_name} src={image} /></figure>
                </Link>
            </div>
            <div className="card-block-info">
                <div className="card-profile">
                    <Link to={`/candidate/${candidate.id}`}>
                        <strong>{candidate.profile.first_name} {candidate.profile.last_name}</strong>
                    </Link>
                    <span className="text-sm">{candidate.profile.title || 'Professional'}</span>
                    <div className="rate-reviews-small">
                        <span><img src="/assets/imgs/theme/icons/star.svg" alt="rating" /></span>
                        <span><img src="/assets/imgs/theme/icons/star.svg" alt="rating" /></span>
                        <span><img src="/assets/imgs/theme/icons/star.svg" alt="rating" /></span>
                        <span><img src="/assets/imgs/theme/icons/star.svg" alt="rating" /></span>
                        <span><img src="/assets/imgs/theme/icons/star.svg" alt="rating" /></span>
                        <span className="ml-10 text-muted text-small">(5.0)</span>
                    </div>
                </div>
                <div className="employers-info d-flex align-items-center justify-content-center mt-15">
                    <span className="d-flex align-items-center">
                        <i className="fi-rr-marker mr-5 ml-0"></i> {candidate.profile.city || 'Location not specified'}
                    </span>
                    {candidate.profile.title && (
                        <span className="d-flex align-items-center ml-25">
                            <i className="fi-rr-briefcase mr-5"></i>{candidate.profile.title}
                        </span>
                    )}
                </div>
                <div className="card-2-bottom card-2-bottom-candidate mt-30">
                    <div className="text-center">
                        {candidate.skills?.length > 0 ? (
                            candidate.skills.slice(0, 3).map(skill => (
                                <span key={skill.id} className="btn btn-tags-sm mb-10 mr-5">
                                    {skill.title}
                                </span>
                            ))
                        ) : (
                            <span className="text-muted">No skills listed</span>
                        )}
                        {candidate.skills?.length > 3 && (
                            <span className="btn btn-tags-sm mb-10 mr-5">+{candidate.skills.length - 3} more</span>
                        )}
                    </div>
                    <div className="text-center mt-25 mb-5">
                        <Link to={`/candidate/${candidate.id}`} className="btn btn-border btn-brand-hover">
                            View profile
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CandidateItem