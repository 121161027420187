import { useState, useEffect } from 'react';
import { UserHttpService } from 'core/services/user';

const useEducations = () => {
    const [educations, setEducations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const loadEducations = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.listEducations();
            setEducations(response.data || []);
        } catch (err) {
            setError(err.message || 'Failed to load educations');
        } finally {
            setIsLoading(false);
        }
    };

    const deleteEducation = async (id) => {
        try {
            await UserHttpService.deleteEducation(id);
            // Refresh the educations list after deletion
            await loadEducations();
        } catch (err) {
            setError(err.message || 'Failed to delete education');
            throw err;
        }
    };

    useEffect(() => {
        loadEducations();
    }, []);

    return {
        educations,
        isLoading,
        error,
        loadEducations,
        deleteEducation
    };
};

export default useEducations; 