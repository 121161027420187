import MainLayout from 'core/layout/MainLayout'
import React from 'react'
import JobsFilterWidget from '../components/JobsFilterWidget'
import JobsList from '../components/JobsList'

function JobsPage() {
  return (
    <MainLayout>
        <JobsFilterWidget />
        <JobsList />
        
        
    </MainLayout>
  )
}

export default JobsPage