import { UserHttpService } from "core/services/user";
import { useMutation, useQueryClient } from "react-query";

/**
 * A custom hook for saving profile changes.
 * @returns {Object} The mutation object from react-query.
 */
function useSaveProfile() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (profileData) => UserHttpService.saveProfile(profileData),
    {
      onSuccess: (data) => {
        // Invalidate or refetch the profile after a successful save
        queryClient.invalidateQueries("profile");
      },
      onError: (error) => {
        console.error("Error saving profile:", error);
      },
    }
  );

  return mutation;
}

export default useSaveProfile;
