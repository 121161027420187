import { UserHttpService } from "core/services/user";
import { useQuery } from "react-query";

/**
 * A custom hook for fetching the user profile.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 *   refetch: Function,
 * }}
 */
function useProfile() {
  const { data, isLoading, isError, refetch } = useQuery(
    "profile",
    () => UserHttpService.loadProfile(),
    {
      onSuccess: (data) => {
        // Optional: handle additional logic on success
      },
      onError: (error) => {
        console.error("Error loading profile:", error);
      },
    }
  );

  return { data, isLoading, isError, refetch };
}

export default useProfile;
