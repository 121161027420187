import { HttpService } from "core/services/http";

export class UserHttpService {
    
    static async loadUsers(page=1) {
        var service = new HttpService();
        var response = await service.get('/auth/user/', null);
        return response;
    }


    static async loginWithAuth0(payload) {
        var service = new HttpService();
        var response = await service.post('/auth/auth0login/', payload);
        return response;
    }

    
   
}

