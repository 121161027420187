import React from 'react';

const CandidateSkills = ({ skills }) => {
    return (
        <div className="candidate-info-section mt-4">
            <h5 className='mb-3'>Skills</h5>
            <div className="skills-list">
                {skills?.length > 0 ? (
                    <div className="row">
                        {skills.map(skill => (
                            <div key={skill.id} className="col-md-4 mb-2">
                                <span className="btn btn-small background-blue-light">
                                    {skill.title}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No skills listed</p>
                )}
            </div>
        </div>
    );
};

export default CandidateSkills; 