import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import QualificationSection from './QualificationSection';
import SkillForm from '../forms/SkillForm';
import useSkills from '../../hooks/useSkills';
import useProfile from '../../hooks/UseProfile';

const SkillsSection = () => {
    const { skills,addSkill, updateSkill, deleteSkill } = useSkills();
    const { refetch:refreshProfile } = useProfile();
    const [error, setError] = useState(null);

    const handleAdd = async (data) => {
        try {
            await addSkill(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to add skill');
        }
    };

    const handleUpdate = async (data) => {
        try {
            await updateSkill(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to update skill');
        }
    };

    const handleDelete = async (id) => {
        // try {
        await deleteSkill(id);
        await refreshProfile();
        // } catch (err) {
        //     setError('Failed to delete skill');
        // }
    };

    const renderSkillItem = (skill) => (
        <Box>
            <Typography variant="subtitle1" className="font-semibold">
                {skill.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {skill.proficiencyLevel}
            </Typography>
            {skill.description && (
                <Typography variant="body2" className="mt-1">
                    {skill.description}
                </Typography>
            )}
        </Box>
    );

    const renderSkillForm = ({ onSubmit, initialData, onCancel }) => (
        <SkillForm 
            onSubmit={onSubmit}
            initialData={initialData}
            onCancel={onCancel}
        />
    );

    return (
        <QualificationSection
            title="Skills"
            items={skills}
            onAdd={handleAdd}
            onEdit={handleUpdate}
            onDelete={handleDelete}
            renderItem={renderSkillItem}
            renderForm={renderSkillForm}
            addButtonText="Add Skill"
            error={error}
        />
    );
};

export default SkillsSection; 