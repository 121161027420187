import MainLayout from 'core/layout/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import UseCandidate from 'cms/features/feature_job/hooks/UseCandidate';
import PreLoader from 'core/components/PreLoader';
import CandidateSkills from '../components/candidate/CandidateSkills';
import CandidateExperience from '../components/candidate/CandidateExperience';
import CandidateEducation from '../components/candidate/CandidateEducation';
import CandidateContactInfo from '../components/candidate/CandidateContactInfo';

function CandidateDetailsPage() {
    const { id } = useParams();
    const { data: candidate, isLoading, isError } = UseCandidate(id);

    if (isLoading) return <PreLoader />;
    if (isError || !candidate) return <p>Error loading candidate details</p>;

    return (
        <MainLayout>
            <section className="section-box mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="candidate-profile mb-50">
                                <div className="profile-header">
                                    <div className="profile-img">
                                        <img 
                                            src={candidate.profile?.avatar || "/assets/imgs/page/candidates/candidate-default.png"} 
                                            alt={`${candidate.profile?.first_name} ${candidate.profile?.last_name}`}
                                            className="rounded-circle"
                                            style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="profile-info">
                                        <h3 className="mb-15">
                                            {candidate.profile?.first_name} {candidate.profile?.last_name}
                                        </h3>
                                        <div className="candidate-meta">
                                            <span className="location text-sm">
                                                <i className="fi-rr-marker"></i> {candidate.profile?.city || 'Location not specified'}
                                            </span>
                                            {candidate.profile?.title && (
                                                <span className="job-title text-sm">
                                                    <i className="fi-rr-briefcase"></i> {candidate.profile.title}
                                                </span>
                                            )}
                                            <span className="experience text-sm">
                                                <i className="fi-rr-time-fast"></i> {candidate.experience || 'Experience not specified'}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="candidate-info-section mt-4">
                                    <h5>About Me</h5>
                                    <p>{candidate.profile?.bio || 'No biography available.'}</p>
                                </div>

                                <CandidateSkills skills={candidate.skills} />
                                <CandidateExperience experiences={candidate.experiences} />
                                <CandidateEducation education={candidate.education} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                            <CandidateContactInfo profile={candidate.profile} />

                            <div className="sidebar-shadow mt-4">
                                <div className="sidebar-heading">
                                    <h6>Additional Information</h6>
                                </div>
                                <div className="sidebar-list">
                                    <div className="availability-status mt-2 p-3">
                                        <h6 className="mb-2">Availability</h6>
                                        <span className={`badge ${candidate.is_available ? 'bg-success' : 'bg-warning'}`}>
                                            {candidate.is_available ? 'Available for Work' : 'Not Available'}
                                        </span>
                                    </div>

                                    <div className="preferred-jobs mt-3 p-3">
                                        <h6 className="mb-2">Preferred Job Types</h6>
                                        {candidate.preferred_job_types?.length > 0 ? (
                                            <ul className="list-unstyled">
                                                {candidate.preferred_job_types.map(type => (
                                                    <li key={type.id} className="mb-2">
                                                        <span className="btn btn-small background-blue-light">
                                                            {type.title}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No preferred job types specified</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default CandidateDetailsPage; 