import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../cms/features/feature_dashboard/Dashboard";
import LogindPage from "cms/features/feature_user/pages/LoginPage";
import JobScreen from "cms/features/feature_job/pages/JobScreen";
import UsersScreen from "cms/features/feature_user/pages/UsersScreen";
import BenefitScreen from "cms/features/feature_job/pages/BenefitScreen";
import TranslationsPage from "cms/features/feature_setting/pages/TranslationsPage";
import HomePage from "features/feature_home/pages/HomePage";
import JobDetailsPage from "features/feature_job/pages/JobDetailsPage";
import PrivateRoute from "./PrivateRoute";
import ProfilePage from "features/feature_user/pages/ProfilePage";
import { HttpService } from "core/services/http";
import JobsPage from "features/feature_job/pages/JobsPage";
import CompaniesPage from "features/feature_job/pages/CompaniesPage";
import CandidatesPage from "features/feature_job/pages/CandidatesPage";
import CandidateDetailsPage from "features/feature_job/pages/CandidateDetailsPage";
import CVPage from "features/feature_cv/pages/CVPage";
import CVDetailsPage from "features/feature_cv/pages/CVDetailsPage";

const MainRoutes = () => {
  const localStorageToken = HttpService.getToken()
  const localStorageInfo = HttpService.getUser()




  const _adminRoutes = () => {
    return (
      <>
        <Route path="/cms/dashboard" element={<Dashboard />} />
        <Route path="/cms/job" element={<JobScreen />} />
        <Route path="/cms/job/benefit" element={<BenefitScreen />} />
        <Route path="/cms/user" element={<UsersScreen />} />
        <Route path="/cms/translations" element={<TranslationsPage />} />

      </>
    );
  };

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/job/:id" element={<JobDetailsPage />} />
      <Route path="/job/:id/:slug" element={<JobDetailsPage />} />
      <Route path="/jobs" element={<JobsPage />} />
      <Route path="/employers" element={<CompaniesPage />} />
      <Route path="/candidates" element={<CandidatesPage />} />
      <Route path="/candidate/:id" element={<CandidateDetailsPage />} />
      <Route path="/cv" element={<CVPage />} />
      <Route path="/cv/:id" element={<CVDetailsPage />} />
      
      <Route path="/cms/login" element={<LogindPage />} />
      
      
      
      {/* Admin Routes */}
      <Route path="/cms" element={localStorageInfo?.role === "admin" ? <Dashboard /> : <LogindPage />} />

      {/* Private Routes */}
      {/* <Route path="/home" element={<PrivateRoute><UserDashboard /></PrivateRoute>} /> */}
      <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />


      {localStorageToken && localStorageInfo?.role === "admin" ? (
        _adminRoutes()
      ) : (
        <></>
      )}
      {/* <Route path="/login" element={<CmsLogin />} /> */}
    </Routes>
  );
};

export default MainRoutes;