import React from 'react'
import JobList from '../components/JobList'
import AdminLayout from 'cms/core/shared/AdminLayout'

function JobScreen() {
  return (
    <AdminLayout>
      <section className="content">
        <div className="container">
              <JobList />
        </div>
      </section>
    </AdminLayout>
  )
}

export default JobScreen