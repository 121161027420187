import { HttpService } from "../services/http";
import Constants from "./constants";
import parse from 'html-react-parser'


export function translate(key, translations) {
    if (!translations) return key;
    if (!key) return "";
    let _language = Utils.getCurrentLanguageName()
    let _translate = translations.find(item => item.key.toLowerCase() === key.toLowerCase());
    if (_translate) {
        let _translate_value = _translate.values.find(item => item.language.abbreviation.toLowerCase() === _language);
        if (_translate_value) {
            return _translate_value.value;
        }

    }


    return key;
}

export class Utils {

    Utils() {

    }


    static SiteTitle = 'Job Flow Connect | ';

    static initialize = function () {
       this.checkSignOutRequired();
    }

    
    static checkSignOutRequired(){
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('signout')) {
            Utils.removeUserSession();
        }
    }

    static initiateTheme = function () {
        const script = document.createElement("script");
        script.src = "/assets/js/theme.init.js";
        document.body.appendChild(script);
    }

    static scrollTo = function (top = 0) {
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        });
    }

    static redirectParticipant = function () {
        const nextPage = "dashboard"; // Default page if no previous page is available

        // Check if the previous page was a course page
        const previousPath = window.location.pathname;
        const coursePagePattern = /^\/course\/(\d+)$/; // Assuming course IDs are numeric
        const match = previousPath.match(coursePagePattern);

        if (match) {
            const courseId = match[1];
            // Redirect to the specific course week page
            window.location.href = `/course/${courseId}/week/1`;
        } else {
            // Redirect to the default page
            window.location.href = nextPage;
        }
    }




    static getCurrentLanguageName = function () {
        let _language_name = window.localStorage.getItem(Constants.LanguageTokenName)
        return _language_name ?? "en";
    }

    static pageUrl = function () {
        return window.location.origin + window.location.pathname;
    }



    static setCurrentLanguageName = function (languageName) {
        window.localStorage.setItem(Constants.LanguageTokenName, languageName.toLowerCase())
    }

    static clearUrl = function () {
        window.history.pushState({}, document.title, window.location.pathname);
    }

    static getUrlParameter = function (parameter, defaultValue = null) {
        const urlParams = new URLSearchParams(window.location.search);
        let _res = urlParams.get(parameter)
        return _res ? _res : defaultValue;
    }

    static delay = (miliseconds) => {
        return new Promise(resolve => setTimeout(resolve, miliseconds));
    }


    static MB = function (file_size) {
        if (!file_size) return "";
        var _size;
        if (file_size < 1024 * 1024) {
            _size = Math.floor(file_size / 1024) + ' KB';
        } else {
            _size = Math.floor(file_size / (1024 * 1024)) + ' MB';
        }
        return _size;
    }

    static nl2br(str, is_xhtml) {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    static getDate = (dateString) => {
        var dateObject = new Date(dateString);
        return dateObject.toDateString();
    };

    static formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    static isAuthenticated = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        return true;
    }

    static isAdmin = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "admin") {
            return true;
        } else return false
    }

    static isParticipant = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "participant") {
            return true;
        } else return false
    }

    static getUserImage = function (user) {

        if (!user) return null;

        if (user.campaign_participants && user.campaign_participants.length > 0) {
            return HttpService.SiteBase + user.campaign_participants[0].image
        }

        return ""
    }


    static getCurrentLanguageId = () => {
        let lang = localStorage.getItem(Constants.LanguageTokenName);
        //let lang = readCookie("language")
        if (!lang) return 1;

        if (lang === "ku") return 3;
        if (lang === "so") return 2;
        if (lang === "en") return 1;

        return lang;
    };

    static getDefaultValue = (translate, language_id=null) => {

        if(!language_id){
            language_id = this.getCurrentLanguageId();
        }

        if (translate.values) {
            var default_value = translate.values.find((x) => x.language == language_id);
            if (default_value) return default_value.value;
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };

    static getDefaultName = (translate) => {
        let language_id = this.getCurrentLanguageId();

        if(!language_id){
            language_id = 1;
        }

        if(!translate){
            return "";
        }

        if (translate.names) {
            var default_name = translate.names.find((x) => x.language == language_id);
            if (default_name) return default_name.name;
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };

    static truncateTextWithEllipsis(text, limit_words) {
        if(!text) return "";
        if (limit_words > 0 && text.split(" ").length > limit_words) {
            let words = text.split(" ").slice(0, limit_words);
            return words.join(" ") + "...";
        }
        return text;
    }

    static getDefaultDescription = (translate, limit_words = 0) => {
        let language_id = this.getCurrentLanguageId();

        if (translate.names) {
            var default_name = translate.names.find((x) => x.language == language_id);
            if (default_name) {
                let description = default_name.description;
                description = this.truncateTextWithEllipsis(description, limit_words)
                return description;
            }
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };

    static setUserSession = (token, user) => {
        localStorage.setItem(HttpService.TOKEN_KEY, token);
        localStorage.setItem("user", JSON.stringify(user));
    };

    static removeUserSession = () => {
        localStorage.setItem(HttpService.TOKEN_KEY, null);
        localStorage.setItem("user", null);
    };

    static parse = (html) => {
        return parse(html)
    };

    static parseDescription = (item, words_limit = 0) => {
        if (!item) return "";
        return parse(Utils.getDefaultDescription(item, words_limit))
    };

    static getImage = function (item) {

        if (item && item.image) {
            return item.image;
        }
        return "/assets/images/user.png"

    }

    static timeInSeconds = function (showTime) {

        if (!showTime) return 0;
        // Parsing the time string

        if (showTime?.split(":").length > 1) {
            const [minutes, seconds] = showTime?.split(":").map(Number);
            const showTimeInSeconds = (minutes * 60) + seconds;
            return showTimeInSeconds;

        }

        return 0;


    }

    static timeAgo(dateInput) {
        const date = new Date(dateInput);
        const secondsElapsed = Math.floor((new Date() - date) / 1000);
      
        const intervals = [
          { label: 'year', seconds: 31536000 },
          { label: 'month', seconds: 2592000 },
          { label: 'day', seconds: 86400 },
          { label: 'hour', seconds: 3600 },
          { label: 'minute', seconds: 60 },
        ];
      
        for (const interval of intervals) {
          const count = Math.floor(secondsElapsed / interval.seconds);
          if (count >= 1) {
            return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
          }
        }
      
        return `${secondsElapsed} second${secondsElapsed !== 1 ? 's' : ''} ago`;
      }

    static isTranscriptVisible = function (transcript, progress = 0) {

        if (!transcript) return false;
        let _time = this.timeInSeconds(transcript?.time);
        let _end_time = this.timeInSeconds(transcript?.end_time);
        if (!_end_time) _end_time = _time + 5

        return progress >= _time && progress < _end_time;

    }

    static isVideoFullScreen = function () {
        return !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement);
    }





}

