import Footer from 'core/layout/Footer'
import Header from 'core/layout/Header'
import React from 'react'
import AdminHeader from './AdminHeader'
import AdminFooter from './AdminFooter'

function MainLayout({children}) {
  return (
    <>
        <AdminHeader />
          <main className="main">
              {children}
          </main>
        <AdminFooter />
    </>
  )
}

export default MainLayout