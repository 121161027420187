import { useQuery, useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { UserHttpService } from "cms/features/feature_user/service";
import { Utils } from "core/utils";

function useAuth0ServerLogin() {
  const queryClient = useQueryClient();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const {
    data,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["auth0ServerLogin", user?.sub],
    async () => {
      // Fetch the Auth0 access token
      const token = await getAccessTokenSilently();
      // Prepare the payload using available user info from Auth0
      const payload = {
        email: user.email,
        given_name: user.given_name || user.name,
        family_name: user.family_name || "",
        nickname: user.nickname || "",
        picture: user.picture || "",
        // If you have a role field in your frontend, include it:
        role: user.role || "employee", 
        token: token,
      };
      // Post the payload to your backend endpoint
      return await UserHttpService.loginWithAuth0(payload);
    },
    {
      // Only run this query if the user is authenticated
      enabled: isAuthenticated,
      // Optionally, set a stale time if you want to cache the server token for a while
      staleTime: 60 * 60 * 1000, // 5 minutes
      onSuccess: (data) => {
        // Optionally do something with the server response (e.g., cache tokens globally)

        Utils.setUserSession(data.tokens, data)
        // console.log("Server token response:", data);
      },
    }
  );

  // Optionally, you can define a function to invalidate or refetch the query if needed
  const refetchAuth = () => {
    queryClient.invalidateQueries(["auth0ServerLogin", user?.sub]);
    refetch();
  };

  return { data, isLoading, isError, refetchAuth };
}

export default useAuth0ServerLogin;
