import { Utils } from "core/utils";
import { HttpService } from "../http";


export class UserHttpService {

    static async login(body) {
        var service = new HttpService();
        var response = await service.post('/auth/login/', body);
        return response;
    }

    static async logout() {
        Utils.removeUserSession();
    };

    static async clearAuthentication() {
        
    };

    static async loadUser(id) {
        var service = new HttpService();
        var response = await service.get(`/api/user/${id}`, null);
        return response;
    }

    static async saveUser(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            var id = body.id;
            delete body["id"]
            delete body["user"]

            response = await service.put(`/api/user/${id}`, body);
        }
        else
            response = await service.post('/api/register/', body);
        return response;
    }

    static async registerSocial(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/api/register/social/', body);
        return response;
    }

    static async loadProfile() {
        var service = new HttpService();
        var response = await service.get(`/auth/profile/me/`, null);
        return response;
    }

    static async saveProfile(body) {
        console.log("Saving profile For: " + body)
        var service = new HttpService();
        var response;
        response = await service.post(`/auth/profile/update/`, body);
        return response;
    }

    static async updateProfileImage(body) {
        const formData = new FormData();
        formData.append("first_name", body.first_name);
        formData.append("last_name", body.last_name);

        if (body.file) formData.append("image", body.file, body.file.name);

        var service = new HttpService();
        var response;
        response = await service.post(`/auth/profile/update/`, formData, true);
        return response;
    }


    static async doLogin(user) {
        let body = {
            email: user.email,
            password: user.password,
        };

        var service = new HttpService();
        var response;
        response = await service.post(`/auth/login/`, body);

        // console.log(response)

        return response;
    }

    static async registerParticipant(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/api/registerparticipant/', body);
        return response;
    }

    static async resendVerificationEmail(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/auth/resendverificationemail/', body);
        return response;
    }

    // Certificate endpoints
    static async getCertification(id) {
        var service = new HttpService();
        var response = await service.get(`/job/cv/certificate/${id}`);
        return response;
    }

    static async createCertification(data) {
        var service = new HttpService();
        var response = await service.post('/job/cv/certificate/', data);
        return response;
    }

    static async updateCertification(id, data) {
        var service = new HttpService();
        var response = await service.put(`/job/cv/certificate/${id}`, data);
        return response;
    }

    static async deleteCertification(id) {
        var service = new HttpService();
        var response = await service.delete(`/job/cv/certificate/${id}`);
        return response;
    }

    static async listCertifications() {
        var service = new HttpService();
        var response = await service.get('/job/cv/certificate/');
        return response;
    }

    // Skill endpoints
    static async getSkill(id) {
        var service = new HttpService();
        var response = await service.get(`/job/cv/userskill/${id}`);
        return response;
    }

    static async addSkill(data) {
        var service = new HttpService();
        var response = await service.post('/job/cv/userskill/', data);
        return response;
    }

    static async updateSkill(id, data) {
        var service = new HttpService();
        var response = await service.put(`/job/cv/userskill/${id}`, data);
        return response;
    }

    static async deleteSkill(id) {
        var service = new HttpService();
        var response = await service.delete(`/job/cv/userskill/${id}`);
        return response;
    }

    static async listSkills() {
        var service = new HttpService();
        var response = await service.get('/job/cv/userskill/');
        return response;
    }

    // Education endpoints
    static async getEducation(id) {
        var service = new HttpService();
        var response = await service.get(`/job/cv/education/${id}`);
        return response;
    }

    static async createEducation(data) {
        var service = new HttpService();
        var response = await service.post('/job/cv/education/', data);
        return response;
    }

    static async updateEducation(id, data) {
        var service = new HttpService();
        var response = await service.put(`/job/cv/education/${id}`, data);
        return response;
    }

    static async deleteEducation(id) {
        var service = new HttpService();
        var response = await service.delete(`/job/cv/education/${id}`);
        return response;
    }

    static async listEducations() {
        var service = new HttpService();
        var response = await service.get('/job/cv/education/');
        return response;
    }
}

