import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import QualificationSection from './QualificationSection';
import CertificationForm from '../forms/CertificationForm';
import useCertifications from '../../hooks/useCertifications';
import useProfile from '../../hooks/UseProfile';

const CertificationSection = ({ certifications = [] }) => {
    const { addCertification, updateCertification, deleteCertification } = useCertifications();
    const { refreshProfile } = useProfile();
    const [error, setError] = useState(null);

    const handleAdd = async (data) => {
        try {
            await addCertification(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to add certification');
        }
    };

    const handleUpdate = async (data) => {
        try {
            await updateCertification(data);
            await refreshProfile();
        } catch (err) {
            setError('Failed to update certification');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteCertification(id);
            await refreshProfile();
        } catch (err) {
            setError('Failed to delete certification');
        }
    };

    const renderCertificationItem = (cert) => (
        <Box>
            <Typography variant="subtitle1" className="font-semibold">
                {cert.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {cert.issuingOrganization}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Issued: {cert.issueDate}
                {cert.expiryDate && ` • Expires: ${cert.expiryDate}`}
            </Typography>
            {cert.credentialId && (
                <Typography variant="body2" color="textSecondary">
                    Credential ID: {cert.credentialId}
                </Typography>
            )}
            {cert.credentialUrl && (
                <Typography variant="body2" color="primary" component="a" href={cert.credentialUrl} target="_blank">
                    View Credential
                </Typography>
            )}
        </Box>
    );

    const renderCertificationForm = ({ onSubmit, initialData, onCancel }) => (
        <CertificationForm 
            onSubmit={onSubmit}
            initialData={initialData}
            onCancel={onCancel}
        />
    );

    return (
        <QualificationSection
            title="Licenses & Certifications"
            items={certifications}
            onAdd={handleAdd}
            onEdit={handleUpdate}
            onDelete={handleDelete}
            renderItem={renderCertificationItem}
            renderForm={renderCertificationForm}
            addButtonText="Add Certification"
            error={error}
        />
    );
};

export default CertificationSection; 