import React from 'react';
import { Utils } from 'core/utils';

const CandidateExperience = ({ experiences }) => {
    return (
        <div className="candidate-info-section mt-4">
            <h5 className='mb-3'>Experience</h5>
            {experiences?.length > 0 ? (
                experiences.map(exp => (
                    <div key={exp.id} className="experience-item mb-3 p-3 border rounded">
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                <h6 className="mb-1">{exp.title}</h6>
                                <p className="company-name mb-1">{exp.company}</p>
                            </div>
                            <span className="badge bg-light text-dark">
                                {Utils.getDate(exp.start_date)} - {exp.end_date ? Utils.getDate(exp.end_date) : 'Present'}
                            </span>
                        </div>
                        <p className="mt-2 mb-0">{exp.description}</p>
                    </div>
                ))
            ) : (
                <p>No experience listed</p>
            )}
        </div>
    );
};

export default CandidateExperience; 