import { UseJobList } from 'cms/features/feature_job/hooks';
import { Utils } from 'core/utils';
import JobItem from 'features/feature_job/components/JobItem';
import React from 'react'
import { Link } from 'react-router-dom';

function HomeJobsGrid() {
    const { data, isLoading, isError } = UseJobList({ size: 6 });

    return (
        <section className="section-box mt-10">
            <div className="container">
                <h2 className="section-title text-center text-base mb-10 wow animate__animated animate__fadeInUp">Jobs</h2>
                <div className="list-recent-jobs list-job-2-col">
                    <div className="row">
                        {data && data.results?.map((job) => ( 
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <JobItem job={job} />
                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeJobsGrid