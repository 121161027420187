import React from 'react'

function AdminHeader() {
  return (
    <>
    <header className="header sticky-bar" style={{ background:'#efff2224' }}>
        <div className="container">
            <div className="main-header">
                <div className="header-left">
                    <div className="header-logo">
                        <a href="/" className="d-flex"><img alt="jobhub" src="/assets/imgs/theme/jobhub-logo.svg" /></a>
                    </div>
                    <div className="header-nav">
                        <nav className="nav-main-menu d-none d-xl-block">
                            <ul className="main-menu">
                                <li >
                                    <a className="active" href="/cms">Dashboard</a>
                                </li>
                                <li >
                                    <a className="active" href="/cms/job">Jobs</a>
                                </li>
                            </ul>
                        </nav>
                       
                    </div>
                </div>
                <div className="header-right">
                    <div className="block-signin">
                        <a href="#" className="text-link-bd-btom hover-up">Apply Now</a>
                        {/* <a href="#" className="btn btn-default btn-shadow ml-40 hover-up">Sign in</a> */}
                    </div>
                </div>
            </div>
        </div>
    </header>
    
</>
  )
}

export default AdminHeader