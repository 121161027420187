// src/components/SelectCountry.js
import useCountries from 'core/hooks/UseCountry';
import { Utils } from 'core/utils';
import React from 'react';

function SelectCountry({ title = 'Select Country', ...props }) {
  const { data: countries, isLoading, isError } = useCountries();

  console.log(countries);

  return (
    <select className="form-input mr-10 select-active" name='country' {...props}>
      <option value="">{title}</option>
      {countries && countries.results.map((country) => (
        <option key={country.id+'country'} value={country.id}>
          {Utils.getDefaultName(country)}
        </option>
      ))}
    </select>
  );
}

export default SelectCountry;
