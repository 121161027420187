import React, { useEffect } from 'react';
import { Avatar, Box, Button, CircularProgress } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import useProfile from '../hooks/UseProfile';
import useSaveProfile from '../hooks/UserSaveProfile';
import LoadingInside from 'core/components/shared/LoadingInside';
import SelectCountry from 'core/components/shared/select/SelectCountry';

const ProfileEditScreen = () => {
  const { data: profile, isLoading: profileLoading, refetch } = useProfile();
  const { mutate: saveProfile, isLoading: saving } = useSaveProfile();
  const { enqueueSnackbar } = useSnackbar();

  // Initialize react-hook-form with default values
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      homeAddress: '',
      postalCode: '',
      city: '',
      country: '',
      phoneNumber: '',
      about: '',
    },
  });

  // Once profile data is loaded, update the form fields
  useEffect(() => {
    if (profile) {
      reset({
        firstName: profile.first_name || '',
        lastName: profile.last_name || '',
        homeAddress: profile.home_address || '',
        postalCode: profile.postalcode || '',
        city: profile.city || '',
        country: profile.country_id ? profile.country_id.toString() : '',
        phoneNumber: profile.phone_number || '',
        about: profile.about || '',
      });
    }
  }, [profile, reset]);

  // Handle form submission
  const onSubmit = (data) => {
    const body = {
      id: profile.id,
      first_name: data.firstName,
      last_name: data.lastName,
      home_address: data.homeAddress,
      postalcode: data.postalCode,
      city: data.city,
      phone_number: data.phoneNumber,
      about: data.about,
    };

    if (data.country) {
      body.country_id = data.country;
    }

    saveProfile(body, {
      onSuccess: () => {
        enqueueSnackbar('Changes saved successfully', { variant: 'success' });
        refetch();
      },
      onError: () => {
        enqueueSnackbar('Failed to save changes', { variant: 'error' });
      },
    });
  };

  const getProfileImage = (image) => {
    if (!image) return '/default-avatar.png';
    if (image.includes('/api/uploaded_media/https')) {
      return image.replace('/api/uploaded_media/', '');
    }
    return image;
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      className="mb-5 p-3"
    >
      <Box
        p={3}
        bgcolor="white"
        boxShadow={2}
        borderRadius={2}
        width="100%"
      >
        <LoadingInside loading={profileLoading} />
        <form className="col-xl-9 col-md-12 mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="row wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="mb-30">
                <Avatar
                  alt={`${profile?.first_name || ''} ${profile?.last_name || ''}`}
                  src={getProfileImage(profile?.image)}
                  sx={{ width: 100, height: 100, margin: '0 auto' }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('firstName')} 
                  placeholder="First Name" 
                  type="text" 
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('lastName')} 
                  placeholder="Last Name" 
                  type="text" 
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <SelectCountry 
                      {...field} 
                      title="Select Country"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('city')} 
                  placeholder="City" 
                  type="text" 
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('homeAddress')} 
                  placeholder="Street Address" 
                  type="text" 
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('postalCode')} 
                  placeholder="Postal Code" 
                  type="text" 
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="input-style mb-20">
                <input 
                  {...register('phoneNumber')} 
                  placeholder="Phone Number" 
                  type="tel" 
                />
              </div>
            </div>
            <div className="col-12">
              <div className="textarea-style mb-30">
                <textarea 
                  {...register('about')} 
                  placeholder="About Me"
                  style={{ height: '200px' }}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={saving}
                fullWidth
                size="large"
              >
                {saving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
};

export default ProfileEditScreen;
