// src/hooks/useCVList.js
import { JobHttpService } from 'cms/features/feature_job/service';
import { useQuery } from 'react-query';

function useCVList(params) {
  const { data, isLoading, error } = useQuery(['cvList', params], () =>
    JobHttpService.loadCVList(params)
  );
  return { data, isLoading, error };
}

export default useCVList;
