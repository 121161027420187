import React from 'react'
import { UseJobList } from '../hooks';
import { Utils } from 'core/utils';
import { Link, useParams } from 'react-router-dom';
import DeleteDialog from 'core/components/shared/DeleteDialog';

function JobList() {
    const { data, isLoading, isError,removeItemFromList} = UseJobList({size: 50});
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
    const [refresh, setRefresh] = React.useState(0);
    const { id } = useParams();


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = (id) => {
        setDeleteDialogIsOpen(false);
        removeItemFromList(selectedId)
        // setRefresh(refresh + 1)
      }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title"></h3>

                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{ width: "350px" }}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />

                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Salary</th>
                                    <th>User</th>
                                    <th>Company</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.results.map((job) => (
                                    <tr key={job.id + "job"}>
                                        <td>{job.id}</td>
                                        <td>{job.title}</td>
                                        <td>{job.salary}</td>
                                        <td>{job.user.profile.first_name + " " + job.user.profile.last_name}</td>
                                        <td>{job.companyy?.title}</td>
                                        <td>{job.status} <span className="tag tag-success">Approved</span></td>
                                        <td>{Utils.getDate(job.created_at)}</td>
                                        <td>
                                            <div className="btns">
                                                <Link to={`/cms/job/${job.id}`} href="#edit"  >
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                                <button className="btn" onClick={(e) => { e.stopPropagation(); _handleDelete(e, job.id) }}>
                                                    <i className="fa fa-trash text-danger"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                )}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <DeleteDialog
                url={`/job/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />

        </div>
    )
}

export default JobList