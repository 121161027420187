import { Utils } from 'core/utils'
import React from 'react'
import { Link } from 'react-router-dom'

function JobItem({job}) {
    return (
            <div className="card-job hover-up wow animate__animated animate__fadeInUp">
                <div className="card-job-top">
                    <div className="card-job-top--image">
                        <a href="employers-single.html">
                            <figure><img alt="jobhub" src="/assets/imgs/page/job/digital.png" /></figure>
                        </a>
                    </div>
                    <div className="card-job-top--info">
                        <h6 className="card-job-top--info-heading lan-kfont"><Link to={`/job/${job.id}`}>{job.title}</Link></h6>
                        <div className="row">
                            <div className="col-md-9">
                                <a href="employers-list.html"><span className="card-job-top--company">{job.company?.title}</span></a>
                                <span className="card-job-top--location text-sm ml-1"><i className="fi-rr-marker"></i> {job.city ? Utils.getDefaultName(job.city) : 'New York,NY'}</span>

                                {job.jobtypes.length > 0 && (
                                    <span className="card-job-top--type-job text-sm"><i className="fi-rr-briefcase"></i>{job.jobtypes[0].title}</span>
                                )}
                                <span className="card-job-top--post-time text-sm"><i className="fi-rr-clock"></i> {Utils.timeAgo(job.updated_at)}</span>
                            </div>
                            <div className="col-md-3 text-md-end text-start p-0">
                                <span className="card-job-top--price">£{job.salary ?? (job.pay_maximum)}<span>/{job.pay_rate}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-job-description mt-20 kfont">{Utils.truncateTextWithEllipsis(job.description, 30)}</div>
                <div className="card-job-bottom mt-25">
                    <div className="row">
                        <div className="col-lg-9 col-sm-8 col-12">
                            <a href="job-grid.html" className="btn btn-small background-urgent btn-pink mr-5">Urgent</a>
                            {job.schedules.length > 0 && (
                                <a href="job-grid-2.html" className="btn btn-small background-blue-light mr-5">{job.schedules[0].title}</a>
                            )}
                            {job.jobtypes.length > 0 && (
                                <a href="job-grid.html" className="btn btn-small background-6 disc-btn">{job.jobtypes[0].title}</a>
                            )}
                        </div>
                        <div className="col-lg-3 col-sm-4 col-12 text-end pt-5 pt-sm-15">
                            <a href="#" className="text-lg color-muted"><i className="fi-rr-shield-check"></i></a>
                            <a href="#" className="ml-5 text-lg color-muted"><i className="fi-rr-bookmark"></i></a>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default JobItem