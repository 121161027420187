import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import useCertificationForm from 'features/feature_user/hooks/useCertificationForm';
import useProfile from 'features/feature_user/hooks/UseProfile';

const CertificationForm = ({ onCancel, id }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            certificationName: '',
            issuingOrganization: '',
            issueDate: null,
            expiryDate: null,
            credentialId: '',
            credentialUrl: ''
        }
    });

    const { isLoading, error, data, saveCertification } = useCertificationForm(id);
    const { refreshProfile } = useProfile();

    useEffect(() => {
        if (data) {
            const formData = {
                ...data,
                issueDate: data.issueDate ? dayjs(data.issueDate) : null,
                expiryDate: data.expiryDate ? dayjs(data.expiryDate) : null,
            };
            reset(formData);
        }
    }, [data, reset]);

    const handleFormSubmit = async (formData) => {
        try {
            const processedData = {
                ...formData,
                issueDate: formData.issueDate ? formData.issueDate.format('YYYY-MM-DD') : null,
                expiryDate: formData.expiryDate ? formData.expiryDate.format('YYYY-MM-DD') : null,
            };
            await saveCertification(processedData);
            await refreshProfile();
            onCancel();
        } catch (err) {
            // Error is handled by the hook and displayed in the form
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box className="space-y-4">
                {error && (
                    <Typography color="error" className="mb-4">
                        {error}
                    </Typography>
                )}

                <TextField
                    fullWidth
                    label="Certification Name"
                    {...register('certificationName', { required: 'Certification name is required' })}
                    error={!!errors.certificationName}
                    helperText={errors.certificationName?.message}
                />

                <TextField
                    fullWidth
                    label="Issuing Organization"
                    {...register('issuingOrganization', { required: 'Issuing organization is required' })}
                    error={!!errors.issuingOrganization}
                    helperText={errors.issuingOrganization?.message}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Issue Date"
                        value={watch('issueDate')}
                        onChange={(date) => setValue('issueDate', date)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !!errors.issueDate,
                                helperText: errors.issueDate?.message
                            }
                        }}
                    />

                    <DatePicker
                        label="Expiry Date"
                        value={watch('expiryDate')}
                        onChange={(date) => setValue('expiryDate', date)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !!errors.expiryDate,
                                helperText: errors.expiryDate?.message
                            }
                        }}
                    />
                </LocalizationProvider>

                <TextField
                    fullWidth
                    label="Credential ID"
                    {...register('credentialId')}
                    error={!!errors.credentialId}
                    helperText={errors.credentialId?.message}
                />

                <TextField
                    fullWidth
                    label="Credential URL"
                    {...register('credentialUrl')}
                    error={!!errors.credentialUrl}
                    helperText={errors.credentialUrl?.message}
                />

                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update' : 'Add'} Certification
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default CertificationForm; 