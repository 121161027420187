import { UserHttpService } from 'core/services/user';
import { useState, useEffect } from 'react';

export const useSkillForm = (id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (id) {
            loadSkill();
        }
    }, [id]);

    const loadSkill = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await UserHttpService.getSkill(id);
            setData(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const saveSkill = async (formData) => {
        setIsLoading(true);
        setError(null);
        try {
            let response;
            if (id) {
                response = await UserHttpService.updateSkill({ ...formData, id });
            } else {
                response = await UserHttpService.addSkill(formData);
            }
            return response;
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        saveSkill
    };
};

export default useSkillForm; 