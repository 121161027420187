import React from 'react';
import { Typography, Box } from '@mui/material';
import QualificationSection from './QualificationSection';
import EducationForm from '../forms/EducationForm';
import useEducations from 'features/feature_user/hooks/useEducations';
import useProfile from 'features/feature_user/hooks/UseProfile';
import { UserHttpService } from 'core/services/user';

const EducationSection = () => {
    const { educations, isLoading, error, loadEducations, deleteEducation } = useEducations();
    const { refreshProfile } = useProfile();

    const handleAdd = async (data) => {
        try {
            await UserHttpService.createEducation(data);
            await Promise.all([loadEducations(), refreshProfile()]);
        } catch (err) {
            throw new Error('Failed to add education');
        }
    };

    const handleUpdate = async (data) => {
        try {
            await UserHttpService.updateEducation(data.id, data);
            await Promise.all([loadEducations(), refreshProfile()]);
        } catch (err) {
            throw new Error('Failed to update education');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteEducation(id);
            await refreshProfile();
        } catch (err) {
            throw new Error('Failed to delete education');
        }
    };

    const renderEducationItem = (edu) => (
        <Box>
            <Typography variant="subtitle1" className="font-semibold">
                {edu.degree} in {edu.field_of_study}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {edu.institution}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {edu.start_date} - {edu.end_date || 'Present'}
            </Typography>
            {edu.description && (
                <Typography variant="body2" className="mt-1">
                    {edu.description}
                </Typography>
            )}
        </Box>
    );

    const renderEducationForm = ({ onSubmit, initialData, onCancel }) => (
        <EducationForm 
            onSubmit={onSubmit}
            initialData={initialData}
            onCancel={onCancel}
        />
    );

    return (
        <QualificationSection
            title="Education"
            items={educations}
            onAdd={handleAdd}
            onEdit={handleUpdate}
            onDelete={handleDelete}
            renderItem={renderEducationItem}
            renderForm={renderEducationForm}
            addButtonText="Add Education"
            error={error}
            isLoading={isLoading}
        />
    );
};

export default EducationSection; 