// src/hooks/useCVDetail.js
import { useQuery } from 'react-query';
import { JobHttpService } from 'cms/features/feature_job/service';

const useCVDetail = (id) => {
  return useQuery(['cvDetail', id], () => JobHttpService.loadCV(id), {
    enabled: !!id, // Only run the query if id is provided
  });
};

export default useCVDetail;
