// src/hooks/useCountries.js
import { BaseHttpService } from 'cms/core/services';
import { useQuery } from 'react-query';

/**
 * Fetches the list of countries from BaseHttpService.
 * Assumes the returned data is an array of objects with properties: code and name.
 */
function useCountries() {
  return useQuery('countries', () => BaseHttpService.loadCountries());
}

export default useCountries;