import React from 'react'

function AdminFooter() {
  return (
    <footer className="footer mt-50">
        <div className="container">
            <div className="footer-bottom mt-50">
                <div className="row">
                    <div className="col-md-6">
                        Copyright ©2025 <a href="https://jobflowconnect.com"><strong>JFC</strong></a>. All Rights Reserved
                    </div>
                    <div className="col-md-6 text-md-end text-start">
                        <div className="footer-social">
                            <a href="#" className="icon-socials icon-facebook"></a>
                            <a href="#" className="icon-socials icon-twitter"></a>
                            <a href="#" className="icon-socials icon-instagram"></a>
                            <a href="#" className="icon-socials icon-linkedin"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default AdminFooter