

export class HttpService {

    static ApiBase = "https://jobflowconnect.com/api"; 
    // static ApiBase = "http://127.0.0.1:8000"; 
    static SiteBase = "https://jobflowconnect.com";
    static TOKEN_KEY = "jfct24";

    headers = new Headers();
    
    static getToken = () => {
        return localStorage.getItem(HttpService.TOKEN_KEY) || null;
    };

    static setToken = (token) => {
        localStorage.setItem(HttpService.TOKEN_KEY,token);
    };

    static setUser = (user) => {
        localStorage.setItem("user",JSON.stringify(user));
    };

    static getUser = () => {
        let userObj = localStorage.getItem("user") || null
        if(!userObj) return null;
        return JSON.parse(userObj);
    };

    static signOut = () => {
        localStorage.removeItem(HttpService.TOKEN_KEY);
        localStorage.removeItem("user")
    };

    getUserToken = () => {
        const userObjString = localStorage.getItem("user");
        const userObj = userObjString ? JSON.parse(userObjString) : null;
        return userObj && userObj.tokens ? userObj.tokens.access : null;
      };

    setHeaders() {
       this.headers.append("Content-Type", "application/json");
       this.headers.append("Accept", "application/json");    
        const auth = this.getUserToken();
        if (auth) this.headers.append("Authorization", "Bearer " + auth);

    }

    

    async get(url, params) {
        // Create and set headers
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");   
        const auth = this.getUserToken();
        if (auth) {
          headers.append("Authorization", "Bearer " + auth);
        }   
      
        // Build the full URL with query parameters if provided
        let fullUrl = `${HttpService.ApiBase}${url}`;
        if (params && typeof params === "object") {
          const queryString = new URLSearchParams(params).toString();
          fullUrl += (url.includes('?') ? '&' : '?') + queryString;
        }

      
        // Make the GET request
        const response = await fetch(fullUrl, {
          headers: new Headers(headers),
          method: 'GET',
        });
      
        const data = await response.json();
      
        // Handle unauthorized access
        if (response.status === 401) {
          window.localStorage.removeItem("authenticate");
          HttpService.setToken("");
          window.location.reload();
        }
      
        return data;
      }
      

    async post(url,body,hasFile)  {
        console.log("POST : " + url);
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        // headers.append("Access-Control-Allow-Origin", "*")

        const auth = this.getUserToken();
        if (auth!= null) headers.append("Authorization", "Bearer " + auth); 
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'POST',
            body: hasFile ? body : JSON.stringify(body),
        });



        const data = await response.json();

        console.log(data)

        return data;
    }

    async put(url,body,hasFile=false)  {

        console.log(JSON.stringify(body))

        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PUT',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        // console.log(response.body)


        return data;
    }

    async patch(url,body,hasFile)  {
        
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    async delete(url) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'DELETE',
        });

        return ;
    }

    async updateActive(url,is_active) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        var body = {
            "is_active" : is_active
        }

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    

    
}

