import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const { user, isAuthenticated, loginWithRedirect,logout,getAccessTokenSilently   } = useAuth0();


  const toggleMobileMenu = (e) => {
    e.preventDefault();
    setMobileMenuActive((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setMobileMenuActive(false);
  };

  useEffect(() => {
    if (mobileMenuActive) {
      document.body.classList.add('mobile-menu-active');
    } else {
      document.body.classList.remove('mobile-menu-active');
    }
    return () => {
      document.body.classList.remove('mobile-menu-active');
    };
  }, [mobileMenuActive]);

  return (
    <>
      {/* Burger icon that appears on mobile to open/close the menu */}
      <div
        className={`burger-icon burger-icon-white ${mobileMenuActive ? 'burger-close' : ''}`}
        onClick={toggleMobileMenu}
      >
        <span className="burger-icon-top"></span>
        <span className="burger-icon-mid"></span>
        <span className="burger-icon-bottom"></span>
      </div>

      {/* Overlay that closes the mobile menu when clicked */}
      {mobileMenuActive && (
        <div className="body-overlay-1" onClick={closeMobileMenu}></div>
      )}

      {/* Mobile menu header */}
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${mobileMenuActive ? 'sidebar-visible' : ''}`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="user-account">
              <img src="/assets/imgs/avatar/ava_1.png" alt="jobhub" />
              <div className="content">
                <h6 className="user-name">
                  
                  {isAuthenticated ? (
                    <Link to={'/profile'} className="text-brand">{user.name}</Link>
                    ) : (
                        <Link onClick={() => loginWithRedirect()}>Welcome, <span className="text-brand">User</span></Link>
                    )}
                </h6>
                {/* <p className="font-xs text-muted">You have 2 new messages</p> */}
              </div>
            </div>
            {/* Burger icon inside the mobile menu to close it */}
            <div
              className={`burger-icon burger-icon-white ${mobileMenuActive ? 'burger-close' : ''}`}
              onClick={toggleMobileMenu}
            >
              <span className="burger-icon-top"></span>
              <span className="burger-icon-mid"></span>
              <span className="burger-icon-bottom"></span>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li>
                      <Link className="active" to="/" onClick={closeMobileMenu}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/jobs" onClick={closeMobileMenu}>
                        Browse Jobs
                      </Link>
                    </li>
                    <li className="has-children">
                      <Link to="/employers" onClick={closeMobileMenu}>
                        Employers
                      </Link>
                      {/* Sub-menu items could be added here */}
                    </li>
                    <li className="has-children">
                      <Link to="/candidates" onClick={closeMobileMenu}>
                        Candidates
                      </Link>
                      {/* Sub-menu items could be added here */}
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="/profile" onClick={closeMobileMenu}>
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/cv/" onClick={closeMobileMenu}>
                      My CVs
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={closeMobileMenu}>
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mobile-social-icon mb-50">
                <h6 className="mb-25">Follow Us</h6>
                <Link to="#">
                  <img src="/assets/imgs/theme/icons/icon-facebook.svg" alt="JobFlowConnect" />
                </Link>
                <Link to="#">
                  <img src="/assets/imgs/theme/icons/icon-twitter.svg" alt="JobFlowConnect" />
                </Link>
                <Link to="#">
                  <img src="/assets/imgs/theme/icons/icon-instagram.svg" alt="JobFlowConnect" />
                </Link>
                <Link to="#">
                  <img src="/assets/imgs/theme/icons/icon-pinterest.svg" alt="JobFlowConnect" />
                </Link>
                <Link to="#">
                  <img src="/assets/imgs/theme/icons/icon-youtube.svg" alt="JobFlowConnect" />
                </Link>
              </div>
              <div className="site-copyright">
                Copyright 2022 © JobFlowConnect. <br />
                Designed by AliThemes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
