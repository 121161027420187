import { useQuery } from "react-query";
import { JobHttpService } from "../service";

function UseCandidate(id) {
    const {
        data,
        isLoading,
        isError,
    } = useQuery(
        ["candidate", id],
        () => JobHttpService.loadCandidate(id),
        {
            enabled: !!id,
            onSuccess: (data) => {
                // console.log(data);
            }
        }
    );

    return { data, isLoading, isError };
}

export default UseCandidate; 