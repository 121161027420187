// src/pages/CVDetailsPage.jsx
import MainLayout from 'core/layout/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import useCVDetail from '../hooks/useCVDetail';
import WidgetCVTitle from '../components/WidgetCvTitle';
import SkillsSection from 'features/feature_user/components/profile/SkillsSection';
import EducationSection from 'features/feature_user/components/profile/EducationSection';
import LanguageSection from 'features/feature_user/components/profile/LanguageSection';

function CVDetailsPage() {
  // Get the CV ID from the URL
  const { id } = useParams();

  // Load the CV using the custom hook
  const { data: cv, isLoading, error } = useCVDetail(id);

  return (
    <MainLayout>
      <div className='container'>
        <WidgetCVTitle cv={cv} />


        {/* Experiences */}

        <div className="mb-3">
          <SkillsSection cv={cv}   />
        </div>
        <EducationSection  cv={cv} />

        <LanguageSection cv={cv}  />

        {/* <References cv={cv}  /> */}
        {/* <Certificates  cv={cv} /> */}
      </div>
    </MainLayout>
  );
}

export default CVDetailsPage;
