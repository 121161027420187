import React from 'react'
import Header from './Header'
import Footer from './Footer'

function MainLayout({children}) {
  return (
    <>
        {/* <div id="preloader-active">
            <div className="preloader d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                    <div className="text-center">
                        <img src="assets/imgs/theme/loading.gif" alt="jobhub" />
                    </div>
                </div>
            </div>
        </div> */}
        <Header />
        
        <main className="main">
            {children}
        </main>
        <Footer />
    </>
  )
}

export default MainLayout