import { useQuery } from "react-query";
import { JobHttpService } from "../service";

/**
 * A custom React hook for fetching a single job item by ID.
 * @param {string | number} jobId - The ID of the job to fetch.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 * }}
 */
function useJobItem(jobId) {
    const {
        data,
        isLoading,
        isError,
    } = useQuery(
        ["job_item", jobId],
        () => JobHttpService.loadJob(jobId),  // Assuming this method exists
        {
            enabled: !!jobId,  // Prevent query if jobId is undefined/null
        }
    );

    return { data, isLoading, isError };
}

export default useJobItem;
