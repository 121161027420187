import MainLayout from 'core/layout/MainLayout';
import React from 'react';
import RecentJobslist from '../components/RecentJobslist';
import useJobItem from 'cms/features/feature_job/hooks/UseJobItem';
import { useParams } from 'react-router-dom';
import PreLoader from 'core/components/PreLoader';
import { Utils } from 'core/utils';

function JobDetailsPage() {
    const { id } = useParams();
    const { data: job, isLoading, isError } = useJobItem(id);


    if (isLoading) return <PreLoader />;
    if (isError || !job) return <p>Error loading job details</p>;

    return (
        <MainLayout>
            <section className="section-box mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="job-single-header mb-50">
                                <h3 className="mb-15">{job.title}</h3>
                                <div className="job-meta">
                                    <span className="company">{job.company?.title || 'Unknown Company'}</span>
                                    <span className="location text-sm"><i className="fi-rr-marker"></i> {job.address || 'No location specified'}</span>
                                    <span className="type-job text-sm"><i className="fi-rr-briefcase"></i> {job.jobtypes?.map(jt => jt.title).join(', ') || 'N/A'}</span>
                                    <span className="post-time text-sm"><i className="fi-rr-clock"></i> {new Date(job.created_at).toLocaleDateString()}</span>
                                </div>
                            </div>

                            <div className="job-overview">
                                <div className="row">
                                    <div className="col-md-4 d-flex">
                                        <div className="sidebar-icon-item"><i className="fi-rr-briefcase"></i></div>
                                        <div className="sidebar-text-info ml-10">
                                            <span className="text-description mb-10">Job Type</span>
                                            <strong className="small-heading">{job.jobtypes?.map(jt => jt.title).join(', ') || 'N/A'}</strong>
                                        </div>
                                    </div>
                                    <div className="col-md-4 d-flex mt-sm-15">
                                        <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                                        <div className="sidebar-text-info ml-10">
                                            <span className="text-description mb-10">Location</span>
                                            <strong className="small-heading">{job.company?.city || 'Unknown'}</strong>
                                        </div>
                                    </div>
                                    <div className="col-md-4 d-flex mt-sm-15">
                                        <div className="sidebar-icon-item"><i className="fi-rr-dollar"></i></div>
                                        <div className="sidebar-text-info ml-10">
                                            <span className="text-description mb-10">Salary</span>
                                            <strong className="small-heading">{job.pay_minimum && job.pay_maximum ? `$${job.pay_minimum} - $${job.pay_maximum} / ${job.pay_rate}` : 'Not specified'}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-30">
                                <div class="col-md-4 d-flex">
                                    <div class="sidebar-icon-item"><i class="fi-rr-clock"></i></div>
                                    <div class="sidebar-text-info ml-10">
                                        <span class="text-description mb-10">Date posted</span>
                                        <strong class="small-heading">{Utils.timeAgo(job.created_at)}</strong>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex mt-sm-15">
                                    <div class="sidebar-icon-item"><i class="fi-rr-time-fast"></i></div>
                                    <div class="sidebar-text-info ml-10">
                                        <span class="text-description mb-10">Expiration date</span>
                                        <strong class="small-heading">{job.expire_date ?? ''}</strong>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex mt-sm-15">
                                    <div class="sidebar-icon-item"><i class="fi-rr-briefcase"></i></div>
                                    <div class="sidebar-text-info ml-10">
                                        <span class="text-description mb-10">Job Title</span>
                                        <strong class="small-heading"></strong>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="content-single mt-30">
                                <h5>Job Description</h5>
                                <p>{job.description || 'No job description available.'}</p>

                                <h5>Schedules</h5>
                                <ul>
                                    {job.schedules.length > 0 ? (
                                        job.schedules.map(schedule => <li key={schedule.id}>{schedule.title}</li>)
                                    ) : (
                                        <li>No schedules specified</li>
                                    )}
                                </ul>

                                <h5>Benefits</h5>
                                <ul>
                                    {job.benefits.length > 0 ? (
                                        job.benefits.map(benefit => <li key={benefit.id}>{benefit.title}</li>)
                                    ) : (
                                        <li>No benefits listed</li>
                                    )}
                                </ul>

                                <h5>Company Details</h5>
                                <p><strong>{job.company?.title}</strong></p>
                                <p><i className="fi-rr-globe"></i> <a href={`https://${job.company?.website}`} target="_blank" rel="noopener noreferrer">{job.company?.website}</a></p>
                                <p><i className="fi-rr-phone-call"></i> {job.company?.phone_number || 'No contact available'}</p>
                                <p><i className="fi-rr-user"></i> Hiring Manager: {job.company?.manager_name || 'N/A'}</p>
                            </div>

                            <div className="single-apply-jobs">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <a href={`mailto:${job.contact_email}`} className="btn btn-default mr-15">Apply now</a>
                                        <a href="#" className="btn btn-border">Save job</a>
                                    </div>
                                </div>
                            </div>

                            <RecentJobslist />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                            <div className="sidebar-shadow">
                                <div className="sidebar-heading">
                                    <div className="avatar-sidebar">
                                        <figure>
                                            <img alt={job.company?.title} src={job.user?.profile?.thumb || "/assets/imgs/page/job-single/avatar-job.png"} />
                                        </figure>
                                        <div className="sidebar-info">
                                            <span className="sidebar-company">{job.company?.title || 'Unknown Company'}</span>
                                            <span className="sidebar-website-text">{job.company?.website || 'No website'}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="sidebar-list-job">
                                    <ul>
                                        <li>
                                            <div className="sidebar-icon-item"><i className="fi-rr-briefcase"></i></div>
                                            <div className="sidebar-text-info">
                                                <span className="text-description">Job Type</span>
                                                <strong className="small-heading">{job.jobtypes?.map(jt => jt.title).join(', ') || 'N/A'}</strong>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                                            <div className="sidebar-text-info">
                                                <span className="text-description">Location</span>
                                                <strong className="small-heading">{job.company?.city || 'Unknown'}</strong>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-icon-item"><i className="fi-rr-dollar"></i></div>
                                            <div className="sidebar-text-info">
                                                <span className="text-description">Salary</span>
                                                <strong className="small-heading">{job.pay_minimum && job.pay_maximum ? `$${job.pay_minimum} - $${job.pay_maximum} / ${job.pay_rate}` : 'Not specified'}</strong>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="sidebar-team-member pt-40">
                                    <h6 className="small-heading">Employer</h6>
                                    <div className="sidebar-list-member">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <figure><img alt={job.user?.profile?.first_name} src={job.user?.profile?.avatar} /></figure>
                                                    <p>{job.user?.profile?.first_name} {job.user?.profile?.last_name}</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="sidebar-team-member pt-40">
                                    <h6 className="small-heading">Contact Info</h6>
                                    <div className="info-address">
                                        <span><i className="fi-rr-paper-plane"></i> <span>{job.contact_email || 'No contact email'}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default JobDetailsPage;
