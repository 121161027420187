import React from 'react';
import { Utils } from 'core/utils';

const CandidateEducation = ({ education }) => {
    return (
        <div className="candidate-info-section mt-4">
            <h5 className='mb-3'>Education</h5>
            {education?.length > 0 ? (
                education.map(edu => (
                    <div key={edu.id} className="education-item mb-3 p-3 border rounded">
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                <h6 className="mb-1">{edu.degree}</h6>
                                <p className="institution mb-1">{edu.institution}</p>
                            </div>
                            <span className="badge bg-light text-dark">
                                {Utils.getDate(edu.start_date)} - {edu.end_date ? Utils.getDate(edu.end_date) : 'Present'}
                            </span>
                        </div>
                        <p className="mt-2 mb-0">{edu.description}</p>
                    </div>
                ))
            ) : (
                <p>No education history listed</p>
            )}
        </div>
    );
};

export default CandidateEducation; 