// src/components/WidgetCVTitle.jsx
import React, { useState } from 'react';
import CustomModal from 'cms/core/shared/dialogs/CustomModal';

const WidgetCVTitle = ({ cv, editable = true }) => {
  const [showModal, setShowModal] = useState(false);

  const showEditDialog = () => {
    setShowModal(true);
  };

 

  return (
    <div className="relative p-4 mb-3 border border-gray-300 rounded-lg text-center bg-white">
      {/* Profile Picture */}
      <div>
        <img
          src={cv && cv.image ? cv.image : '/assets/images/user.png'}
          alt="User"
          className="w-100p h-100p rounded-full bg-gray-300 object-cover"
        />
      </div>

      {/* Name */}
      <div className="mt-2 font-bold text-lg">
        {cv ? `${cv.name} ${cv.last_name}` : 'Name LastName'}
      </div>

      {/* Title */}
      <div className="mt-2 font-bold text-base">
        {cv ? cv.title : 'Title'}
      </div>

      {/* Contact Info */}
      <div className="mt-3 flex justify-center gap-x-5 items-center">
        <div className="flex items-center">
          {/* <FiMail className="text-gray-500" /> */}
          <span className="ml-2 font-bold text-sm">
            {cv ? cv.email : 'Email'}
          </span>
        </div>
        <div className="flex items-center">
          {/* <FiPhone className="text-gray-500" /> */}
          <span className="ml-2 font-bold text-sm">
            {cv ? cv.phone : '+44'}
          </span>
        </div>
      </div>

      {/* Edit Icon */}
      {editable && (
        <button
          onClick={showEditDialog}
          className="absolute right-2 top-2 bg-transparent border-0 cursor-pointer text-gray-500"
        >
          {/* <FiEdit size={20} /> */}
        </button>
      )}

      {/* Edit Modal */}
      {showModal && (
        <CustomModal open={showModal} setOpen={setShowModal} >
          {/* <CVTitleForm cv={cv} onUpdate={handleUpdate} /> */}
        </CustomModal>
      )}
    </div>
  );
};

export default WidgetCVTitle;
