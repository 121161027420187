import React from 'react';

const CandidateContactInfo = ({ profile }) => {
    return (
        <div className="sidebar-shadow">
            <div className="sidebar-heading">
                <h6 className='mb-3'>Contact Information</h6>
            </div>
            <div className="sidebar-list">
                <ul>
                    {profile?.email && (
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-envelope"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Email</span>
                                <strong className="small-heading">{profile.email}</strong>
                            </div>
                        </li>
                    )}
                    {profile?.phone && (
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-phone-call"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Phone</span>
                                <strong className="small-heading">{profile.phone}</strong>
                            </div>
                        </li>
                    )}
                    {profile?.website && (
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-globe"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Website</span>
                                <strong className="small-heading">
                                    <a href={profile.website} target="_blank" rel="noopener noreferrer">
                                        {profile.website}
                                    </a>
                                </strong>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default CandidateContactInfo; 