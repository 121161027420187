import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Button, Checkbox, FormControlLabel, CircularProgress, Typography } from '@mui/material';
import useEducationForm from '../../hooks/useEducationForm';
import useProfile from 'features/feature_user/hooks/UseProfile';

const EducationForm = ({ onCancel, id }) => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            degree: '',
            institution: '',
            startDate: '',
            endDate: '',
            description: '',
            isCurrentlyStudying: false
        }
    });

    const { isLoading, error, data, saveEducation } = useEducationForm(id);
    const { refreshProfile } = useProfile();

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data, reset]);

    const isCurrentlyStudying = watch('isCurrentlyStudying');

    const handleFormSubmit = async (formData) => {
        try {
            await saveEducation(formData);
            await refreshProfile();
            onCancel();
        } catch (err) {
            // Error is handled by the hook and displayed in the form
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box className="space-y-4">
                {error && (
                    <Typography color="error" className="mb-4">
                        {error}
                    </Typography>
                )}

                <TextField
                    fullWidth
                    className='mb-2'
                    label="Degree/Course"
                    {...register('degree', { required: 'Degree is required' })}
                    error={!!errors.degree}
                    helperText={errors.degree?.message}
                />

                <TextField
                    fullWidth
                    className='mb-2'
                    label="Institution"
                    {...register('institution', { required: 'Institution is required' })}
                    error={!!errors.institution}
                    helperText={errors.institution?.message}
                />

                <TextField
                    fullWidth
                    className='mb-2'
                    type="date"
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    {...register('startDate', { required: 'Start date is required' })}
                    error={!!errors.startDate}
                    helperText={errors.startDate?.message}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            {...register('isCurrentlyStudying')}
                        />
                    }
                    label="I am currently studying here"
                />

                {!isCurrentlyStudying && (
                    <TextField
                        fullWidth
                        type="date"
                        className='mb-2'
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        {...register('endDate', { 
                            required: !isCurrentlyStudying ? 'End date is required' : false 
                        })}
                        error={!!errors.endDate}
                        helperText={errors.endDate?.message}
                    />
                )}

                <TextField
                    fullWidth
                    multiline
                    rows={1}
                    className='mb-2'
                    label="Description"
                    {...register('description')}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                />

                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update' : 'Add'} Education
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default EducationForm; 