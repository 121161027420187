import { UseJobList } from 'cms/features/feature_job/hooks';
import React from 'react'
import JobItem from './JobItem';

function RecentJobslist() {
    const { data, isLoading, isError } = UseJobList({ size: 6 });

    return (
        <div class="single-recent-jobs">
            <h4 class="heading-border"><span>Recent jobs</span></h4>
            <div class="list-recent-jobs">
                {data && data.results.map((job) => (
                    <div className="col-12">
                        <JobItem job={job} />
                    </div>

                ))}

                <div class="mb-20">
                    <a href="job-grid.html" class="btn btn-default">Explore more</a>
                </div>
            </div>
        </div>
    )
}

export default RecentJobslist