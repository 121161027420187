import React from 'react';
import { Link } from 'react-router-dom';
import HeaderSignInButton from 'features/feature_home/components/HeaderSignInButton';
import MobileMenu from './MobileMenu';

function Header() {
  return (
    <>
      <header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link to="/" className="d-flex">
                  <img alt="jobhub" src="/assets/imgs/theme/jobhub-logo.svg" />
                </Link>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  <ul className="main-menu">
                    <li>
                      <Link className="active" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/jobs">Browse Jobs</Link>
                    </li>
                    <li>
                      <Link to="/employers">Employers</Link>
                    </li>
                    <li>
                      <Link to="/candidates">Candidates</Link>
                    </li>
                    <li>
                      <Link to="/cv">CV Builder</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-right">
              <div className="block-signin">
                <HeaderSignInButton />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Render the mobile menu widget */}
      <MobileMenu />
    </>
  );
}

export default Header;
