import MainLayout from 'core/layout/MainLayout'
import React from 'react'
import JobsFilterWidget from '../components/JobsFilterWidget'
import CandidatesList from '../components/CandidatesList'

function CandidatesPage() {
  return (
    <MainLayout>
        <JobsFilterWidget />
        <CandidatesList />
    </MainLayout>
  )
}

export default CandidatesPage