import SelectCountry from 'core/components/shared/select/SelectCountry'
import React from 'react'

function HomeSearchWidget() {
  return (
    <section className="section-box bg-banner-about banner-home-custom">
            <div className="banner-hero">
                <div className="banner-inner">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="block-banner">
                                <div className="form-find mw-720 ">
                                    <form className="wow animate__animated animate__fadeInUp">
                                        <input type="text" className="form-input input-keysearch mr-10" placeholder="Job title" />
                                        <SelectCountry />
                                        <button className="btn btn-default btn-find wow animate__animated animate__fadeInUp">Find now</button>
                                    </form>
                                </div>
                                <div className="list-tags-banner mt-60 text-center wow animate__animated animate__fadeInUp">
                                    <strong>Popular Searches:</strong>
                                    <a href="#">Designer</a>, <a href="#">Developer</a>, <a href="#">Web</a>, <a href="#">Engineer</a>, <a href="#">Senior</a>,
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default HomeSearchWidget