import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'core/layout/MainLayout';
import CustomModal from 'cms/core/shared/dialogs/CustomModal';
import useCVList from '../hooks/useCVList';
import CVItem from '../components/CVItem';
import CVTitleForm from '../components/CVTitleForm';

function CVPage() {
  const { data, isLoading, error } = useCVList({ size: 30 });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Show the modal (can mimic a bottom sheet)
  const showCreateNewCVModal = () => {
    setShowModal(true);
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Callback when a new or updated CV is received
  const handleCVUpdate = (cv) => {
    closeModal();
    // Navigate to the CV details page (e.g. /cv/details/{cvId})
    navigate(`/cv/details/${cv.id}`);
  };

  return (
    <MainLayout>
      <section className="section-box mt-80">
        <div className="container">
          {/* Button to add a new CV */}
          <div className="mb-10" style={{ padding: '15px' }}>
            <button className="btn btn-primary" onClick={showCreateNewCVModal}>
              Add a CV
            </button>
          </div>

          {/* Display the list state: loading, error, empty or the list */}
          {isLoading ? (
            <div className="p-8 text-center">
              <span>Loading...</span>
            </div>
          ) : error ? (
            <div className="text-center">
              <span>{error}</span>
            </div>
          ) : data && data.length === 0 ? (
            <div className="text-center">
              <span>No Items Found</span>
            </div>
          ) : (
            <div className="cv-list">
              {data && data.results?.map((cv, index) => (
                <div key={cv.id+'cv'} className="cv-item-wrapper">
                  <CVItem cv={cv} />
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Modal for creating a new CV */}
      {showModal && (
        <CustomModal
        open={showModal} 
        setOpen={(value) => setShowModal(value)}>
          <CVTitleForm cv={null} onUpdate={handleCVUpdate} />
        </CustomModal>
      )}

      {/* Spacer at the bottom */}
      <div style={{ height: '120px' }}></div>
    </MainLayout>
  );
}

export default CVPage;
